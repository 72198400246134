
  #notFound404 {
    padding-top: 15vh;
    padding-bottom: 30px;
    min-height: 50vh;
  }
  
  #notFound404 #description-container {
    background-color: #ecedef;
    background-size: cover;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  #notFound404 #description-container .container {
    height: 300px;
    align-items: center;
    display: flex;
  }
  
  #notFound404 #description-container .container .row {
    flex-direction: column;
  }
  
  #notFound404 .img-container {
    margin-top: -272px;
    width: 100%;
  }
  
  #notFound404 .img-container .row {
    width: 100%;
  }
  
  #notFound404 .img-container .row .col-lg-4 {
    padding: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  #notFound404 .img-container .row .col-lg-4 figure figcaption {
    margin-top: 15px;
  }
  
  #notFound404 .img-container .row .col-lg-4 .btn-sissa-secondary {
    width: 200px;
    font-weight: 500;
  }
  
  .nf404-text {
    text-align: center;
  }
  
  .nf404-text .title-1 {
    width: 100vw;
    padding: 7px;
    background-color: #a62531;
    color: #ffffff;
    font-size: 4vw;
  }