.fotoPrincipalLP{
    width: 100%;
    max-height: 316px;
}

.textoImagen{
    background: url('../../assets/img/info/Banner_sissa_digital.jpg');
    background-size: cover;
}

.tituloFoto{
    margin-top: 1em;
    font-size: 2.5em;
    color: white;
    font-family: "Roboto", sans-serif;
}
.textoFoto{
    font-size: 1.7em;
    color: #2c4977;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
}
.textoFoto2{
    font-size: 1.7em;
    color: white;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 1000;
    margin-top: -0.5em;
}
.botonFoto{
    background-color: gray;
    color: white;
    margin-top: 3em;
    margin-bottom: 1.5em;
    font-weight: bolder;
}

.foto2{
    width: 100%;
    margin-top: 9em;
    border-radius: 1em;
    font-family: "Roboto", sans-serif;
}

.imagenLogo{
    width: 40%;
    margin-top: 2em;
}
.imagenLogo2{
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
}

.cardProductos{
    position:absolute;
    top:-4em;
    border-radius: 0.75em;
    background-color: #f7f7f7;
    box-shadow: 0 2px 20px 0 rgba(1, 9, 24, 0.397);
    transition: all 0.4s ease-out;
    border: transparent;
}

.vertical{
    border-left: 2px solid #e5e5e5;
    height: 160px ;
    position:absolute;
    top:-10px;
}
.textoCentroProductos{
    text-align: center;
    color: #1a37b1;
    font-weight: 900;
    font-size: 1.5em;
    text-transform: uppercase;
}

.lineaDerecha{
    border-right: 2px solid #e5e5e5;
    height: 100%;
}

.lineaDerecha2{
    border-right: 2px solid #e5e5e500;
    height: 100%;
}

.EspacioArriba{
    margin-top: 11em;
}

.imagenLinea{
    margin-top: 30%;
    width: 100%;
}
.imagenLinea2{
    margin-top: 10%;
    width: 100%;
}
.imagenLinea3{
    width: 100%;
}

.tituloAzul{
    color: #304196;
    font-weight: 800;
    font-size: 1.3em;
}
.letraNegritaP{
    color: black;
    font-weight: 600;
    font-size: 1em;
}
.letraNegritaG{
    color: black;
    font-size: 1.3em;
    font-weight: 700;
}
.letraSinNegritas{
    color: black;
}

.textoVideo{
    font-size: 1.1em;
    font-family: "Roboto", sans-serif;
    text-align: justify;
}
.btnDescargar{
    background-color: #1a37b1;
    border-color: #1a37b1;
    border-radius: 0.9em;
    text-transform: uppercase;
    color: white;
    font-family: "Roboto", sans-serif;
    font-size: 1.2em;
    font-weight: 700;
}
.btnDescargar:hover{
    background-color: #1635b1;
    border-color: #1635b1;
    box-shadow: 0 2px 20px 0 rgba(1, 9, 24, 0.397);
    transition: all 0.4s ease-out;
}

@media (min-width: 300px) and (max-width: 999px){
    .lineaDerecha2{
        border-right: 2px solid #e5e5e5;
    }
    .lineaDerecha{
        border-right: 2px solid #e5e5e500;
        height: 100%;
    }
    .cardProductos{
        position:relative;
    }
    .EspacioArriba{
        margin-top: 1em;
    }
    .imagenLinea{
        margin-top: 0px;
        width: 100%;
    }
    .imagenLinea2{
        margin-top: 0px;
        width: 100%;
    }
    .imagenLogo2{
        width: 100%;
        margin-top: 0em;
        margin-bottom: 0em;
    }
    .textoFoto{
        margin-top: 7em;
        font-size: 2em;
    }
    .textoFoto2{
        margin-bottom: 4em;
        font-size: 2em;
    }
    .imagenLogo{
        margin-top: 6em;
        width: 100%;
    }
}
