
@font-face {
    font-family: "MrDeHaviland";
    src: url('../../fonts/MrDeHaviland-Regular.ttf');
  }
  
  
  .swiper-container {
      width: 100%;
      height: 90vh;
    }
    .espacioArribaCarrusel{
      margin-top: 4em;
    }
  
    .swiper-button-prev, .swiper-button-next{
      color:#ffffff9d;
    }
  
    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: #000;
      opacity: 1;
      background: rgba(131, 131, 131, 0.37);
    }
  
    .swiper-pagination-bullet-active {
      color: #fff;
      background: #ffffff9d;
    }
  
   .swiper-slide{
     overflow: hidden;
   }
  
  
    .background-img{
      /* background-image: url('../../assets/img/carrusel/banner1.png');     */
      background-size: cover;
      background-position:center;
      width: 100%;
      height: 100%;
      animation-name: scale_bg;
      animation-duration: 5000ms;
      animation-timing-function: ease-out;
      animation-iteration-count: infinite;
      animation-play-state: paused;
    }
  
  
  
  
    @keyframes scale_bg {
      0%{
        transform: scale(1);
      }
      50%{
        transform: scale(1.1);
      }
      100%{
        transform: scale(1);
      }
    }
  
  
    .content-slide{
      flex-direction: column;
      align-items: center;
      justify-content:  center;
      height: 100%;
      position: absolute;
      top:0px;
      left: 0px;
      width: 100%;
    }
  
    .content-slide-left{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 90%;
    }
    .content-slide-right{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 90%;
    }
  
    .content-slide-img{
      width: 75%;
      height: auto;    
    }
    
    @media(min-width:770px){
      .content-slide{      
        flex-direction: row;
        align-items: center;
      }
    
      .content-slide-left{
        width: 50%;
      }
      .content-slide-right{
        width: 50%;
      }
    }
  
  
    /* BANNER ANIVERSARIO */
  
    .banner-aniv-bg{
      background-image: url('../../assets/img/carrusel/banner-aniv-bg-small.png');
      animation-play-state: paused;
    }
    @media(min-width:770px){
      .banner-aniv-bg{
        background-image: url('../../assets/img/carrusel/banner-aniv-bg.png');
      }
    }
    .swiper-slide-active .banner-aniv-bg{
      animation-play-state: running;
    } 
    @media(min-width:770px){
      .banner-aniv-bg{
        background-image: url('../../assets/img/carrusel/banner-aniv-bg.png');
      }
    }
  
     .banner-aniv-left{
      align-items: flex-end;
      justify-content: flex-end;
    }
    .banner-aniv-img{
      width: 95%;
      height: auto;
  
    }
    .swiper-slide-active .content-slide .content-slide-left .banner-aniv-img{
      animation: bounceInLeft;
      animation-duration: 2.5s;
    }
    
    .banner-aniv-text-1{
      font-size: 12vw;
      color: #754c24;
      font-family: 'MrDeHaviland', cursive;
    }
   
  
    .banner-aniv-text-2{
      font-size: 6vw;
      color: #ae8c5a;
      font-family: 'Cabin', sans-serif;
    }
  
  
    .banner-aniv-linetext-1{
      visibility: visible;    
    }
    .swiper-slide-active .content-slide .content-slide-right .banner-aniv-linetext-1{
      animation: zoomIn;    
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-fill-mode: forwards;
      animation-duration: 3.5s;
      animation-delay: 0s;
    }
    .banner-aniv-linetext-2{
      visibility: visible;
    }
    .swiper-slide-active .content-slide .content-slide-right .banner-aniv-linetext-2{
      animation: zoomIn;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-fill-mode: forwards;
      animation-duration: 9.5s;
      animation-delay: 0s;
    }
    .banner-aniv-linetext-3{
      visibility: visible;
    }
    .swiper-slide-active .content-slide .content-slide-right .banner-aniv-linetext-3{
      animation: zoomIn;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-fill-mode: forwards;
      animation-duration: 3.5s;
      animation-delay: 0s;
    }
  
    .banner-aniv-linetext-4{
      visibility: visible;
    }
    .swiper-slide-active .content-slide .content-slide-right .banner-aniv-linetext-4{   
      animation: zoomIn;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-fill-mode: forwards;
      animation-duration: 9.5s;
      animation-delay: 0s;
    }
  
  
    
    @media(min-width:1024px){
      .banner-aniv-left{     
        justify-content: center;
      }
      .banner-aniv-right{     
        justify-content: center;
      }
      .banner-aniv-img{
        width: 75%;     
      }
      .swiper-slide-active .content-slide .content-slide-left .banner-aniv-img{
        animation: bounceInUp;
        animation-duration: 2.5s;
      }
      
      .banner-aniv-text-1{
        font-size: 9vw;
      
      }
      .banner-aniv-text-2{
        font-size: 3.5vw;
      }
    
    }
  
  
    /* BANNER SISSA INFRAESTUCTURA */
    .banner-si-bg{
      background-image: url('../../assets/img/carrusel/banner-si-bg-small.png');
      animation-duration: 20s;
      animation-play-state: paused;
    }
    @media(min-width:770px){
      .banner-si-bg{
        background-image: url('../../assets/img/carrusel/banner-si-bg.png');
      }
    }
  
    .banner-si-right{
      display: none;
    }
    .swiper-slide-active .banner-si-bg{
      animation-play-state: running;
    } 
  
    .banner-si-left{    
      align-items: center;
      justify-content: space-around;
      width: 100%;
    }
    .banner-si-img{
      width: 95%;
      height: auto;
  
    }
  
    .banner-si-logo{
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .banner-si-logo img{
      width: 70%;
      
    }
    .swiper-slide-active .content-slide .content-slide-left .banner-si-logo img{
      animation: backInLeft;
      animation-duration: 1.5s;    
    }
    .swiper-slide-active .content-slide .content-slide-right .banner-si-logo img{
      animation: backInLeft;
      animation-duration: 1.5s;
    }
  
    .swiper-slide-active .content-slide .content-slide-right .banner-si-img{
      animation: slideInUp;
      animation-duration: 2.5s;
    }
    .banner-si-text-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 80%;
    }
    .banner-si-text-1{
      font-size: 10vw;
      font-weight: 600;
      color: rgb(0, 0, 0);
      font-family: 'Cabin', sans-serif;
      text-align: center;
  
      background-color: #d09120;
      box-shadow: 0px 5px #4d4d4d ;
      border-radius: 5px;
      width: 100%;
      padding: 5px 0px;
      
      opacity: 0;
      
    } 
  
    .banner-si-text-2{
      font-size: 10vw;
      font-weight: 600;
      color: #fff;
      font-family: 'Cabin', sans-serif;
      padding: 10px 0px;
      opacity: 0;
    }
    .banner-si-text-3{
      font-size: 10vw;
      font-weight: 600;
      color: #fff;
      font-family: 'Cabin', sans-serif;
      padding: 10px 0px;
      opacity: 0;
    }
  
    .swiper-slide-active .content-slide .content-slide-left .banner-si-text-1{
      animation: zoomIn;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;    
    }
    .swiper-slide-active .content-slide .content-slide-left .banner-si-text-2{
      animation: zoomIn;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;
    }
    .swiper-slide-active .content-slide .content-slide-left .banner-si-text-3{
      animation: zoomIn;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;
      animation-delay: 2s;
    }
  
    
    @media(min-width:1024px){
      
      .banner-si-left{     
        justify-content: space-between;
        width: 50%;
      }
      .banner-si-logo{
        justify-content: flex-start;
      }
      .banner-si-logo img{
        width: 40%;
        margin-left: 10%;
      }
      .banner-si-right{     
        display: flex;
        justify-content: flex-end;
        margin-top: 70px;
      }
      .banner-si-img{
        width: 95%;
       
      }
      .banner-si-text-1{
        font-size: 3vw;
      
      }
      .banner-si-text-2{
        font-size: 3vw;
      }
      .banner-si-text-3{
        font-size: 3vw;
      }
    
    }
    @media(min-width:1280px){
      .banner-si-img{
        width:85%;
      }
    }
  
    @media(min-width:1280px){
      .banner-si-img{
        width:70%;
      }
    }
  
  
  
    /* BANNER SISSA MONITORING */
    .banner-sm-bg{
      background-image: url('../../assets/img/carrusel/banner-sm-bg-small.png');
      animation-duration: 20s;
      animation-play-state: paused;
    }
    @media(min-width:770px){
      .banner-sm-bg{
        background-image: url('../../assets/img/carrusel/banner-sm-bg.png');
      }
    }
  
    .banner-sm-left{
      display: none;
    }
    .swiper-slide-active .banner-sm-bg{
      animation-play-state: running;
    } 
  
    .banner-sm-left{    
      align-items: center;
      justify-content: space-around;
      width: 100%;
    }
    .content-slide-left .banner-sm-logo{
      display: none;
    }
    .banner-sm-img{
      width: 95%;
      height: auto;
  
    }
  
    .banner-sm-logo{
      width: 100%;
    }
    .banner-sm-logo img{
      width: 70%;
    }
    .swiper-slide-active .content-slide .content-slide-left .banner-sm-img{
      animation: slideInUp;
      animation-duration: 2.5s;
    }
  
    .banner-sm-right{
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
    .banner-sm-right .logo-and-text-container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .banner-sm-right .logo-and-text-container .banner-sm-logo{
      display: flex;
      justify-content: center;
    }
    .swiper-slide-active .content-slide .banner-sm-right .logo-and-text-container .banner-sm-logo img{
      animation:backInLeft;
      animation-duration: 1.5s;
    }
    .swiper-slide-active .content-slide .content-slide-left .banner-sm-logo img{
      animation:backInLeft;
      animation-duration: 1.5s;
    }
  
    .banner-sm-text-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 80%;
    }
    .banner-sm-text-1{
      font-size: 8vw;
      font-weight: 600;
      color: rgb(255, 255, 255);
      font-family: 'Cabin', sans-serif;
      text-align: center;
  
      background-color: #991818;
      box-shadow: -0.3rem 0.3rem #591111 ;
      border-radius: 0.3rem;
     
      padding: 0.5rem 1rem;
      
      opacity: 0;
      
    } 
  
    .banner-sm-text-2{
      font-size: 6vw;
      font-weight: 600;
      color: #fff;
      text-shadow: #000 3px 3px 3px;
      font-family: 'Cabin', sans-serif;
      padding: 10px 0px;
      opacity: 0;
    }
    .banner-sm-text-3{
      font-size: 5vw;
      font-weight: 500;
      color: #fff;
      text-shadow: #000 3px 3px 3px;
      font-family: 'Cabin', sans-serif;
      padding: 10px 0px;
      opacity: 0;
    }
  
    .swiper-slide-active .content-slide .banner-sm-right .logo-and-text-container .banner-sm-text-1{
      animation: zoomIn;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;    
    }
    .swiper-slide-active .content-slide .banner-sm-right .logo-and-text-container .banner-sm-text-2{
      animation: zoomIn;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;
    }
    .swiper-slide-active .content-slide .banner-sm-right .logo-and-text-container .banner-sm-text-3{
      animation: zoomIn;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;
      animation-delay: 2s;
    }
  
    .banner-sm-right-bar{
      width: 10%;    
    }
    .banner-sm-right-bar img{
      visibility: hidden;  
    }
    .swiper-slide-active .content-slide .banner-sm-right .banner-sm-right-bar img{
      animation: slideInRight;
      animation-duration: 2s;
      animation-delay: 2s;
      animation-fill-mode: forwards;
    }
  
    
    @media(min-width:1024px){
      
      .banner-sm-left{     
        justify-content: space-between;
        width: 60%;
        align-items: flex-start;
      }
      .banner-sm-left{     
        display: flex;
        justify-content: space-between;      
      }
      .content-slide-left .banner-sm-logo{
        display: flex;
        width: 50%;
        margin-left: 10%;
      }
  
      .banner-sm-right{
        width: 50%;
      }
      .banner-sm-right .logo-and-text-container .banner-sm-logo{
        display: none;
      }
      .banner-sm-img{
        width: 95%;
       
      }
      .banner-sm-text-1{
        font-size: 3vw;
        box-shadow: -0.4rem 0.4rem rgb(141, 20, 20) ;
        border-radius: 0.4rem;
      }
      .banner-sm-text-2{
        font-size: 3vw;
      }
      .banner-sm-text-3{
        font-size: 2.5vw;
      }
    
    }
    @media(min-width:1280px){
      .banner-sm-img{
        width:90%;
      }
      .banner-sm-text-3{
        font-size: 2.5vw;
      }
    }
  
  
  
  
    /* BANNER SISSA DIGITAL */
    .banner-sd-bg{
      background-image: url('../../assets/img/carrusel/banner-sd-sm-bg.png');
      animation-duration: 20s;
      animation-play-state: paused;
    }
    @media(min-width:770px){
      .banner-sd-bg{
        background-image: url('../../assets/img/carrusel/banner-sd-bg.png');
      }
    }
  
    .banner-sd-right{
      display: none;
    }
    .swiper-slide-active .banner-sd-bg{
      animation-play-state: running;
    } 
  
    .banner-sd-left{    
      align-items: center;
      justify-content: space-around;
      width: 100%;
    }
    .banner-sd-img{
      width: 95%;
      height: auto;
  
    }
  
    .banner-sd-logo{
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: rgba(240, 255, 255, 0.534);
    }
    .banner-sd-logo img{
      width: 70%;
      
    }
    .swiper-slide-active .content-slide .content-slide-left .banner-sd-logo img{
      animation: backInLeft;
      animation-duration: 1.5s;    
    }
    .swiper-slide-active .content-slide .content-slide-right .banner-sd-logo img{
      animation: backInLeft;
      animation-duration: 1.5s;
    }
  
    .swiper-slide-active .content-slide .content-slide-right .banner-sd-img{
      animation: slideInUp;
      animation-duration: 2.5s;
    }
    .banner-sd-text-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 50%;
    }
    .banner-sd-text-1{
      font-size: 8vw;
      font-weight: 600;
      color: #fff;
      font-family: 'Cabin', sans-serif;
      text-align: center;
  
      background-color: #284b7d;
      box-shadow: 0px 5px #123968 ;
      border-radius: 5px;
      width: 100%;
      padding: 5px 0px;
      
      opacity: 0;
      
    } 
  
    .banner-sd-bottom{
      padding: 15px;
      background-color: #284b7dbb;
    }
    .banner-sd-text-2{
      font-size: 4vw;
      font-weight: 600;
      color: #fff;
      font-family: 'Cabin', sans-serif;
      padding: 10px 0px;
      opacity: 0;
    }
    .banner-sd-text-3{
      font-size: 3vw;
      font-weight: 600;
      color: #fff;
      font-family: 'Cabin', sans-serif;
      padding: 10px 0px;
      opacity: 0;
    }
  
    .swiper-slide-active .content-slide .content-slide-left .banner-sd-text-1{
      animation: zoomIn;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;    
    }
    .swiper-slide-active .content-slide .content-slide-left .banner-sd-text-2{
      animation: zoomIn;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;
    }
    .swiper-slide-active .content-slide .content-slide-left .banner-sd-text-3{
      animation: zoomIn;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;
      animation-delay: 2s;
    }
  
    
    @media(min-width:1024px){
      
      .banner-sd-left{     
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
      }
      .banner-sd-logo{
        justify-content: flex-start;
        background-color: transparent;
      }
      .banner-sd-logo img{
        width: 20%;
        margin-left: 5%;
      }
      .banner-sd-right{     
        display: flex;
        justify-content: flex-end;
        margin-top: 70px;
        margin-left: -50%;
      }
      .banner-sd-img{
        width: 95%;
       
      }
      .banner-sd-text-container{
        align-items: flex-start;
      }
      .banner-sd-text-1{
        font-size: 3vw;
        padding: 3px 15px;
        width: 30%;
        margin-left: 5vw;
      }
  
      .banner-sd-bottom{
        padding-left: 5vw;
        width: 95vw;
      }
      .banner-sd-text-2{
        font-size: 2vw;
      }
      .banner-sd-text-3{
        font-size: 1.5vw;
      }
    
    }
  
  
    @media(min-width:1280px){
      .banner-sd-img{
        width:60%;
      }
    }
  
  
  /* BANNER SISSA Monitoring II */
  .banner-sm2-bg{
    background-image: url('../../assets/img/carrusel/banner-sm2-small-bg.png');
    animation-duration: 20s;
    animation-play-state: paused;
  }
  @media(min-width:770px){
    .banner-sm2-bg{
      background-image: url('../../assets/img/carrusel/banner-sm2-bg.png');
    }
  }
  
  .banner-sm2-right{
    display: none;
  }
  .swiper-slide-active .banner-sm2-bg{
    animation-play-state: running;
  } 
  
  .banner-sm2-left{    
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  
  .banner-sm2-logo{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .banner-sm2-logo img{
    width: 70%;  
  }
  @media(min-width:768px){
    .banner-sm2-logo img{
      width: 50%;
      
    }
  }
  .swiper-slide-active .content-slide .content-slide-left .banner-sm2-logo img{
    animation: backInLeft;
    animation-duration: 1.5s;    
  }
  .swiper-slide-active .content-slide .content-slide-right .banner-sm2-logo img{
    animation: backInRight;
    animation-duration: 1.5s;
  }
  
  
  .banner-sm2-text-container-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
    background-color: #991818;
    box-shadow: -0.4rem 0.4rem rgb(141, 20, 20) ;
    border-radius: 0.4rem;
    padding:0px 1rem;
  }
  .banner-sm2-text-1{
    font-size: 10vw;
    font-weight: 600;
    color: #fff;
    font-family: 'Cabin', sans-serif;
    text-align: center;
    border-radius: 5px;
    width: 100%;
    padding: 3px 0px;  
    opacity: 0;  
  } 
  
  .banner-sm2-text-2{
    font-size: 6.5vw;
    font-weight: 600;
    color: #fff;
    font-family: 'Cabin', sans-serif;
    padding: 3px 0px;
    opacity: 0;
  }
  .banner-sm2-text-3{
    font-size: 6vw;
    font-weight: 600;
    color: #fff;
    font-family: 'Cabin', sans-serif;
    padding: 3px 0px;
    opacity: 0;
  }
  
  .swiper-slide-active .content-slide .content-slide-left .banner-sm2-text-1{
    animation: zoomIn;
    animation-duration: 2.5s;
    animation-fill-mode: forwards;    
  }
  .swiper-slide-active .content-slide .content-slide-left .banner-sm2-text-2{
    animation: zoomIn;
    animation-duration: 2.5s;
    animation-fill-mode: forwards;  
  }
  .swiper-slide-active .content-slide .content-slide-left .banner-sm2-text-3{
    animation: zoomIn;
    animation-duration: 2.5s;
    animation-fill-mode: forwards;  
  }
  
  
  
  
  
  .banner-sm2-text-container-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Cabin', sans-serif;
    opacity: 0;
  }
  
  .banner-sm2-text-4{
    font-size: 6vw;
    color: #fff;
  }
  .banner-sm2-text-5{
    font-size: 5vw;
    color: #fff;
  }
  .banner-sm2-text-6{
    font-size: 5vw;
    color: #fff;
  }
  
  .swiper-slide-active .content-slide .content-slide-left .banner-sm2-text-container-2{
    animation: zoomIn;
    animation-duration: 2.5s;
    animation-fill-mode: forwards; 
    animation-delay: 1s;   
  }
  
  
  @media(min-width:1024px){
  
    .banner-sm2-left{     
      width: 50%;
      justify-content: center;
    }
    .banner-sm2-left .banner-sm2-logo{
      display: none;
    }
  
    .banner-sm2-logo{
      justify-content: flex-end;
   
    }
    .banner-sm2-logo img{
      width: 30%;
      margin-right: 10%;
    }
    .banner-sm2-right{     
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 70px;
    }
  
    .banner-sm2-text-container-1{
      width:65%;
      margin-bottom: 2rem;
    }
    .banner-sm2-text-1{
      font-size: 3vw;
    
    }
    .banner-sm2-text-2{
      font-size: 2vw;
    }
    .banner-sm2-text-3{
      font-size: 2vw;
    }
    .banner-sm2-text-4{
      font-size: 2.5vw;
    
    }
    .banner-sm2-text-5{
      font-size: 2vw;
    }
    .banner-sm2-text-6{
      font-size: 2vw;
    }
  
  }
  
  @media(min-width:1280px){
    .banner-sm2-img{
      width:85%;
    }
  }
  
  
  
  
  
  
  
    /* BANNER SEA */
    /* .banner-sea-bg{
      background-image: url('../../assets/img/carrusel/banner-sea-sm-bg.png');
      animation-duration: 20s;
      animation-play-state: paused;
    }
    @media(min-width:770px){
      .banner-sea-bg{
        background-image: url('../../assets/img/carrusel/banner-sea-bg.png');
      }
    }
  
    .banner-sea-left{
      display: none;
    }
    .banner-sea-right{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      background-color: #12396886;
    }
  
    .banner-sea-img{
      width: 30%;
    }
   */
    /* =========================================== */
    /* .evento-sea-container{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      
    }
  
    .evento-sea-container .header-banner{
   
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 20px 0px;
    }
    .evento-sea-container .header-banner img{
      width: 50%;
    }
  
    .banner-sea-text-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 75% ;
      justify-content: space-around;
    }
  
  
    .banner-sea-foto{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .banner-sea-foto img{
      width: 50%;
      height: auto;
    }
  
    .banner-sea-foto-txt1{
      font-family: 'Cabin', sans-serif;
      color: #fff;
      font-size: 4vw;
    }
    
    .banner-sea-foto-txt2{
      font-family: 'Cabin', sans-serif;
      color: #fff;
      font-size: 4vw;
    }
    
    .banner-sea-foto-txt3{
      font-family: 'Cabin', sans-serif;
      color: #fff;
      font-size: 4vw;
    }
  
    .banner-sea-center-text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .banner-sea-center-text-1{
      font-family: 'Cabin', sans-serif;
      color: #fff;
      font-size: 5vw;
    }
    .banner-sea-center-text-2{
      font-family: 'Cabin', sans-serif;
      color: #fff;
      font-size: 5vw;
      text-align: center;
    }
  
    .banner-sea-center-text img{
      display: none;
    }
  
    .banner-sea-qr{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .banner-sea-qr .qr-sea{
      display: none;
    }
    .banner-sea-btn-registrate{
      font-family: 'Cabin', sans-serif;
      background-color: #991818;
      color: #fff;
      font-size: 4vw;
      padding: 10px;
      border-radius: 24px;  
    }
  
    
  
    .banner-sea-fecha{
      display: flex;
      align-items: center;
    }
    .banner-sea-fecha-txt{
      font-family: 'Cabin', sans-serif;
      color: #fff;
    }
    .banner-sea-fecha img{
      width: 4vw;
      height: auto;
      margin-right: 2vw;
    }
  
    .banner-sea-hora{
      display: flex;
      align-items: center;
    }
    .banner-sea-hora-txt{
      font-family: 'Cabin', sans-serif;
      color: #fff;
    }
    .banner-sea-hora img{
      width: 4vw;
      height: auto;
      margin-right: 2vw;
    }
  
    .banner-sea-right-bar{
      width: 10%;    
    }
    .banner-sea-right-bar img{
      visibility: hidden;  
    }
    .swiper-slide-active .content-slide .banner-sea-right .banner-sea-right-bar img{
      animation: slideInRight;
      animation-duration: 2s;
      animation-delay: 2s;
      animation-fill-mode: forwards;
    }
  
  
  
  @media(min-width:760px){
    .evento-sea-container .header-banner img{
      width: 40%;
    }
    .banner-sea-foto-txt1{
      
      font-size: 3vw;
    }
    
    .banner-sea-foto-txt2{
      
      font-size: 3vw;
    }
    
    .banner-sea-foto-txt3{
      font-size: 3vw;
    }
    .banner-sea-center-text-1{  
      font-size: 4vw;
    }
    .banner-sea-btn-registrate{
      font-size: 3vw;
      margin-bottom: 1vh;
    }
    .banner-sea-fecha-txt{
      font-size: 2.5vw;
    }
    .banner-sea-hora-txt{
      font-size: 2.5vw;
    }
  
  }
  
  @media(min-width:1023px){
    .banner-sea-left{
      display: flex;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.644);
      width: 30%;
    }
    .banner-sea-right{ 
      width: 70%;
      flex-direction: row;
    }
    .evento-sea-container .header-banner img{
      width: 25%;
    }
  
    .banner-sea-text-container{   
      flex-direction: row;
      justify-content: space-between;
    }
  
  
    .banner-sea-foto{
      min-width: 25%;
    }
  
    .banner-sea-foto img{
      width: 80%;
      height: auto;
    }
  
    .banner-sea-foto-txt1{    
      font-size: 1.5vw;
      text-align: center;
    }
    
    .banner-sea-foto-txt2{    
      font-size: 1.5vw;
      text-align: center;
    }
    
    .banner-sea-foto-txt3{    
      font-size: 1.5vw;
      text-align: center;
    }
  
    .banner-sea-center-text{    
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      min-width: 40%;
    }
  
    .banner-sea-center-text-1{
      font-family: 'Cabin', sans-serif;
      color: #fff;
      font-size: 2vw;
    }
    .banner-sea-center-text-2{
      font-family: 'Cabin', sans-serif;
      color: #fff;
      font-size: 2vw;
      text-align: center;
    }
  
    .banner-sea-center-text img{
      display: flex;
    }
    .banner-sea-qr .qr-sea{
      display: flex;
      width: 80%;
      margin-bottom: 1rem;
    }
  
    .banner-sea-btn-registrate{    
      font-size: 2vw; 
    }  
  
     .banner-sea-fecha-txt{
      font-size: 1.5vw;
    }
    .banner-sea-fecha img{
      width: 2vw;
    }
  
  
    .banner-sea-hora-txt{
      font-size: 1.5vw;
    }
    .banner-sea-hora img{
      width: 2vw;
    }
  
  }
   */
  /* Termina banner SEA */
  