/* .imagenCasoExito {
    border-radius: 50%;
    width: 100%;
    height: auto;
    max-width: fit-content;
    border: 1px solid #00000031;
    box-shadow: 0 2px 20px 0 rgba(5, 27, 70, 0.164);
    transition: all 0.4s ease-out;
    border: transparent;
    margin-top: 1em;
    margin-bottom: 1em;
} */

.article-caso-exito {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 1px solid #00000031;
    box-shadow: 0 2px 20px 0 rgba(5, 27, 70, 0.164);
    transition: all 0.4s ease-out;
    border: transparent;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.article-caso-exito img{
    object-fit: cover;
    object-position: center center;
    width: 100%;
    margin: 0 auto;
}

.article-caso-exito .unam, .itw, .bancomext, .gallerias{
   max-width: 100px;
}


.espacioArriba {
    margin-top: 1.5em;
}

.espacioAbajo {
    margin-bottom: 1.5em;
}

.centrar {
    text-align: center;
    font-family: "Cabin", sans-serif;
}

.containerExito {
    box-shadow: 0px 4px 18px 6px #11000050;
}

.fondoExito {
    background-image: url(/static/media/bg-1.8614bdcc.png);
    padding-bottom: 30px;
}