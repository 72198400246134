.vertientes-subsection-icon .st0{fill:#F7F7F7;}
.vertientes-subsection-icon .st1{fill:#5B5B5F;}
.vertientes-subsection-icon .st2{fill:#FFFFFF;}
.vertientes-subsection-icon .st3{fill:#FFFFFF;}
.vertientes-subsection-icon .st4{fill:#FFFFFF;}
.vertientes-subsection-icon .st5{fill:#FFFFFF;}
.noFill{
  fill: none !important;
}


#IconDataCenter .st0{fill:#F7F7F7;}
#IconDataCenter .st1{fill:#5B5B5F;}
#IconDataCenter .st2{fill:none;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
#IconDataCenter .st3{fill:none;}
#IconDataCenter .st4{fill:#FFFFFF;}
#IconIdentificacion .st0{fill:#F7F7F7;}
#IconIdentificacion .st1{fill:#5B5B5F;}
#IconIdentificacion .st2{fill:none;}
#IconIdentificacion .st3{fill:#FFFFFF;}
#IconGeolocalizacion .st0{fill:#F7F7F7;}
#IconGeolocalizacion .st1{fill:#5B5B5F;}
#IconGeolocalizacion .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
#IconGeolocalizacion .st3{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}


.hexagon{
    width: 8vw;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor:pointer;
    animation-duration: 2s;
    animation-name: scaleupHexagon;
    transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hexagon:hover{
    animation-name: scaleupHexagon;
    transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform: scale(1.05);
    animation-iteration-count: 1;
  }

  

  .hexagon_active .st1{
    fill: #A62531;  
  }

  .hexagon_active{ 
    animation-name: scaleupHexagon;
    transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform: scale(1.05);
    animation-iteration-count: 1;
  }
/* 
  .hexagon .st1:hover{
    fill: #A62531;  
   
  } */

  
  @keyframes scaleupHexagon{
    0%{
      transform: scale(1.01);
    }
    70%{
      transform: scale(1.08);
    }
    100%{
      transform: scale(1.05);
    }
  }
  @keyframes scaleupHexagon2{
    0%{
      transform: scale(1.01);
    }
    70%{
      transform: scale(1.08);
    }
    100%{
      transform: scale(1.00);
    }
  }

  @media (min-width:720px){
    .hexagon{
      margin-left: 0rem;
    }
  } 
  @media (min-width:768px){
    .hexagon{
      margin-left: 0.8rem;
    }
  } 
  @media (min-width:860px){
    .hexagon{
      margin-left: 0.7rem;
    }
  } 

  @media (min-width: 960px){
    .hexagon{
      margin-left: 0.4rem ;
    }
  } 
  @media (min-width: 990px){
    .hexagon{
      margin-left: 1.8rem ;
    }
  } 
  @media (min-width: 1024px){
    .hexagon{
      margin-left: 1.8rem ;
    }
  } 
  @media (min-width: 1280px){
    .hexagon{
      margin-left: 2rem ;
    }
  } 

  @media (min-width: 1366px){
    .hexagon{
      width: 6vw;
      margin-left: 2rem ;
      margin-top: -0.5rem;

    }
  } 
  @media (min-width: 1900px){
    .hexagon{
      width: 6vw;
      margin-left: 2rem ;

    }
  } 