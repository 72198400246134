.honeycomb-section {
  flex-direction: column;
}


.section-content-mobile{
    display: block;
  width: 100%;
}

.honeycomb-section{
  min-height: 85vh;
}
.honeycomb-section .section-content {
  display: none;
  position: absolute;
  top: 3rem;
  width: 100%;  
  align-items: flex-start;  
}

.honeycomb-section .section-content .hexagon-icons{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.honeycomb-section .section-content .section-image {
  align-items: center;
  justify-content: center;
}

@media screen and (min-width:1200px){
  
  .honeycomb-section .section-content .section-image {
    padding: 0rem 8rem;
  }
}

@media screen and (min-width:1800px){
  
  .honeycomb-section .section-content .section-image {
    padding: 0rem 3rem;
  }
}
.honeycomb-section .section-content .section-image img {
  width: 100%;
  height: auto;
}

#tsparticles{
  display: none !important;
}

.section-text-list ul{
  list-style: none;
}
.section-text-list ul li i{
  width: 2rem;
  text-align: center;
}

@media screen and (min-width: 768px) {
  #tsparticles{
    display: block !important;
  }
  .section-content-mobile{
    display: none;
  }
  .honeycomb-section .section-content {
    display: flex;
    flex-wrap: nowrap;
  }
  .honeycomb-section .section-content .section-text {
    max-width: 55%;
  }  
}

@media screen and (min-width:768px){
  #carouselNosotros .carousel-control-prev {
    width: 10% !important;
  }
  #carouselNosotros .carousel-control-next {
    width: 10% !important;
  }
  
  
}

@media screen and  (min-width:1440px){
  #carouselNosotros .carousel-control-prev {
    width: 9% !important;
  }
  #carouselNosotros .carousel-control-next {
    width: 9% !important;
  } 
  
}

@media screen and (min-width:1530px){
  #carouselNosotros .carousel-control-prev {
    width: 8% !important;
  }
  #carouselNosotros .carousel-control-next {
    width: 8% !important;
  } 
  
}
#nosotros-slide1 {
    background-image: url("../../assets/img/nosotros/ante-cada-reto-background.png");
  }
  
  @media screen and  (min-width: 768px) {
    #nosotros-slide1 {
      background-image: url("../../assets/img/nosotros/ante-cada-reto-background.png");
    }
  }
  
  #nosotros-slide1-text .title-container {
    background-color: rgba(255, 255, 255, 0.33);
    padding: 1rem;
    border-left: solid 1rem;
    border-left-color: #a62531;
    flex-direction: column;
  }
  
  #nosotros-slide1-text .title-container .title1 {
    font-size: 1.5rem;
    margin-left: 24px;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  #nosotros-slide1-text .title-container .title2 {
    font-size: 3.5rem;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    font-weight: 500;
  }
  
  @media screen and  (min-width: 768px) {
    #nosotros-slide1-text .title-container {
      border-left-width: 1.5rem;
      width: 50%;
    }
    #nosotros-slide1-text .title-container .title1 {
      font-size: 3.2vw;
    }
    #nosotros-slide1-text .title-container .title2 {
      font-size: 7vw;
    }
  }

  @media screen and  (min-width: 1250px) {
    #nosotros-slide1-text .title-container {
      border-left-width: 1.5rem;
      width: 40%;
    }
  
    
  }

  
  @media screen and  (min-width: 768px) {
    #nosotros-slide1 {
      align-items: flex-start;
      padding-left: 1rem;
    }
  }
  
  #nosotros .section-text {
    flex-direction: column;
  }
  
  #nosotros .section-text h3 {
    margin-bottom: 1.5rem;
  }
  
  #nosotros .separator-top {
    height: 2.0rem;
  }
  
  #nosotros .separator-top .row {
    width: 100%;
    padding-top: 0.7rem;
    align-items: flex-start;
  }
  
  #nosotros .separator-bottom {
    background-color: #ffffff;
    height: 2.0rem;
  }
  
  #nosotros .separator-bottom .row {
    width: 100%;
    padding-bottom: 0.7rem;
    align-items: flex-end;
  }
  
  #nosotros .separator-line {
    background-color: #ecedef;
    width: 100%;
    height: 2px;
  }
  
  #nosotros .separator-bottom-lightgray {
    background-color: #ecedef;
    height: 2rem;
  }
  
  #nosotros .separator-bottom-lightgray .row {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0.7rem;
    align-items: flex-end;
  }
  
  #nosotros .separator-bottom-lightgray .row .separator-line {
    background-color: #dddddd;
    height: 2px;
  }
  
  #nosotros-short-description {
    background-color: #ffffff;
    width: 100%;
    min-height: 10vh !important;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
  
  #nosotros-short-description .container .row #short-description-text {
    border-left-color: #a62531;
    border-left-width: 0.5rem;
    border-left-style: solid;
    padding: 0px 0px 0px 1.2rem;
    color: #666666;
  }
  
  #short-description-text {
    color: #000;
  }
  
  #nosotros-ante-cada-reto-una-solucion {
    background-image: url("../../assets/img/nosotros/banner-background1.png");
    background-size: cover;
    width: 100%;
    min-height: 10vh !important;
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  
  #nosotros-ante-cada-reto-una-solucion .container .row {
    align-items: flex-end;
  }
  
  #nosotros-ante-cada-reto-una-solucion .container .row .section-text {
    color: #666666;
  }
  
  #nosotros-ante-cada-reto-una-solucion .container .row .section-text h3 {
    color: #a62531;
  }
  
  #nosotros-ante-cada-reto-una-solucion .container .row .col-md-3 img {
    padding: 2rem;
  }
  
  #short-description-text {
    color: #000;
  }
  
  #nostotros-la-transformacion-es {
    background-color: #a62531;
    width: 100%;
    min-height: 10vh !important;
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  
  #nostotros-la-transformacion-es .container .row {
    align-items: flex-end;
  }
  
  #nostotros-la-transformacion-es .container .row .section-text {
    color: #ffffff !important;
  }
  
  #nostotros-la-transformacion-es .container .row .col-md-3 img {
    padding: 2rem;
  }
  
  @media screen and  (min-width: 768px) {
    #nostotros-la-transformacion-es .container .row .section-text {
      padding-bottom: 6rem;
    }
  }
  
  #nostotros-organizaciones-relacionadas {
    background-color: #ffffff;
    width: 100%;
    min-height: 10vh !important;
  }
  
  #nostotros-organizaciones-relacionadas .container-fluid {
    flex-wrap: wrap;
  }
  
  #nostotros-organizaciones-relacionadas .container-fluid .section-header {
    background-color: #ffffff;
    width: 100vw;
    padding-top: 3rem;
    padding-bottom: 3rem;
    box-shadow: 0px 4px 18px 6px #8888888c;
    align-items: center;
  }
  
  #nostotros-organizaciones-relacionadas .container-fluid .section-header .section-text {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  
  #nostotros-organizaciones-relacionadas .container-fluid .section-header .section-text h3 {
    color: #a62531;
  }
  
  #nostotros-organizaciones-relacionadas .container-fluid .section-header .section-text p {
    color: #5b5b5f;
  }
  
  #nostotros-organizaciones-relacionadas .container-fluid .content {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: nowrap;
  }
  
  #nostotros-socios-de-negocio {
    background-color: #ecedef;
    width: 100%;
    min-height: 10vh !important;
  }
  
  #nostotros-socios-de-negocio .container-fluid {
    flex-wrap: wrap;
  }
  
  #nostotros-socios-de-negocio .container-fluid .section-header {
    background-color: #ffffff;
    width: 100vw;
    padding-top: 3rem;
    padding-bottom: 3rem;
    box-shadow: 0px 4px 18px 6px #8888888c;
    align-items: center;
  }
  
  #nostotros-socios-de-negocio .container-fluid .section-header .section-text {
    align-items: center;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    flex-basis: 85%;
  }
  
  #nostotros-socios-de-negocio .container-fluid .section-header .section-text h3 {
    color: #a62531;
  }
  
  #nostotros-socios-de-negocio .container-fluid .section-header .section-text p {
    color: #5b5b5f;
  }
  
  #nostotros-socios-de-negocio .container-fluid .content {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }
  
  #nostotros-socios-de-negocio .container-fluid .content .col-md-2 {
    padding: 1rem;
  }
  
  #reducimos-riesgos-slide {
    background-image: url("../../assets/img/nosotros/sissa-monitoring/banner-m1.png");
  }
  
  @media screen and  (min-width: 768px) {
    #reducimos-riesgos-slide {
      background-image: url("../../assets/img/nosotros/sissa-monitoring/banner1.png");
    }
  }
  
  #reducimos-riesgos-text {
    padding-left: 2rem;
  }
  
  #reducimos-riesgos-text .gray-bold-title1 {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  #reducimos-riesgos-text .red-thin-title1 {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  #reducimos-riesgos-text .red-bold-title1 {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  #reducimos-riesgos-text .red-rrss-container {
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }

  @media screen and (min-width: 1024px){
    #reducimos-riesgos-text .red-rrss-container {
      margin-left: 1rem;
     
    }
  }
  @media screen and (min-width: 1366px){
    #reducimos-riesgos-text .red-rrss-container {
      margin-left: 4rem;
     
    }
  }
  @media screen and (min-width: 1536px){
    #reducimos-riesgos-text .red-rrss-container {
      margin-left: 6rem;
     
    }
  }

  
  
  @media screen and  (min-width: 575px) {
    #reducimos-riesgos-text .yk-carousel-caption {
      padding-left: 4em;
    }
    #reducimos-riesgos-text .yk-carousel-caption h3 {
      font-size: 4rem;
    }
    #reducimos-riesgos-text .yk-carousel-caption h4 {
      font-size: 3.5rem;
    }
    #reducimos-riesgos-text .yk-carousel-caption h5 {
      font-size: 3rem;
    }
    #reducimos-riesgos-text .yk-carousel-caption h6 {
      font-size: 2.5rem;
    }
  }
  
  @media screen and  (min-width: 768px) {
    #reducimos-riesgos-text #reducimos-riesgos-text {
      padding-left: 5rem;
    }
    #reducimos-riesgos-text .yk-carousel-caption {
      flex-direction: row-reverse !important;
      justify-content: space-around !important;
    }
    #reducimos-riesgos-text .yk-carousel-caption #hacemosDeTusRetos-image {
      height: 610px;
      width: 600px;
    }
  }
  
  @media screen and  (min-width: 1024px) {
    #reducimos-riesgos-text .yk-carousel-caption h3 {
      font-size: 4rem;
    }
    #reducimos-riesgos-text .yk-carousel-caption h4 {
      font-size: 3.5rem;
    }
  }
  
  @media screen and  (min-width: 2048px) {
    #reducimos-riesgos-text .yk-carousel-caption h3 {
      font-size: 6rem;
    }
  }
  
  #reducimos-riesgos-text .gray-bold-title1 {
    font-size: 7.5vw;
  }
  
  #reducimos-riesgos-text .red-thin-title1 {
    font-size: 9vw;
  }
  
  #reducimos-riesgos-text .red-bold-title1 {
    font-size: 17.5vw;
  }
  
  @media screen and  (min-width: 768px) {
    #reducimos-riesgos-text .gray-bold-title1 {
      font-size: 3vw;
    }
    #reducimos-riesgos-text .red-thin-title1 {
      font-size: 3.6vw;
    }
    #reducimos-riesgos-text .red-bold-title1 {
      font-size: 7vw;
    }
  }
  
  #nos-especializamos-slide {
    background-image: url("../../assets/img/nosotros/sissa-monitoring/banner2.png");
  }
  
  @media screen and  (min-width: 768px) {
    #nos-especializamos-slide {
      background-image: url("../../assets/img/nosotros/sissa-monitoring/banner2.png");
    }
  }
  
  #nos-especializamos-text {
    align-items: center;
  }
  
  #nos-especializamos-text .red-background-title {
    font-size: 1.2rem;
  }
  
  #nos-especializamos-text .title1 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #ffffff;
  }
  
  #nos-especializamos-text .title2 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #ffffff;
  }
  
  #nos-especializamos-text .title3 {
    font-size: 0.8rem;
    color: #ffffff;
  }
  
  #nos-especializamos-text .red-background-title {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  #nos-especializamos-text .title1 {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  #nos-especializamos-text .title2 {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  #nos-especializamos-text .title3 {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  @media screen and  (min-width: 768px) {
    #nos-especializamos-slide .yk-carousel-caption {
      align-items: flex-end;
    }
    #nos-especializamos-slide #nos-especializamos-text {
      padding-right: 5rem;
    }
    #nos-especializamos-slide #nos-especializamos-text .red-background-title {
      font-size: 2.5vw;
      padding: 0px 10px;
    }
    #nos-especializamos-slide #nos-especializamos-text .title1 {
      font-size: 3.3vw;
      font-weight: 500;
    }
    #nos-especializamos-slide #nos-especializamos-text .title2 {
      font-size: 4.1vw;
      font-weight: 500;
    }
    #nos-especializamos-slide #nos-especializamos-text .title3 {
      font-size: 1.6vw;
    }
  }
  
  #reducimos-slide {
    background-image: url("../../assets/img/nosotros/sissa-monitoring/banner3.png");
  }
  
  @media screen and  (min-width: 768px) {
    #reducimos-slide {
      background-image: url("../../assets/img/nosotros/sissa-monitoring/banner3.png");
    }
  }
  
  #reducimos-text {
    align-items: center;
  }
  
  #reducimos-text .gray-bold-title1 {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  #reducimos-text .red-thin-title1 {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  #reducimos-text .red-bold-title1 {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  #reducimos-text .red-rrss-container {
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
  }
  
  #reducimos-text .gray-bold-title1 {
    font-size: 9vw;
  }
  
  #reducimos-text .red-thin-title1 {
    font-size: 10vw;
  }
  
  #reducimos-text .red-bold-title1 {
    font-size: 15vw;
  }
  
  @media screen and  (min-width: 768px) {
    #reducimos-text .gray-bold-title1 {
      font-size: 3.3vw;
    }
    #reducimos-text .red-thin-title1 {
      font-size: 3.6vw;
    }
    #reducimos-text .red-bold-title1 {
      font-size: 7vw;
    }
  }
  
  @media screen and  (min-width: 768px) {
    #reducimos-text {
      padding-left: 2rem;
    }
  }
  
  #somos-aliados-slide {
    background-image: url("../../assets/img/nosotros/sissa-monitoring/banner-m1.png");
  }
  
  @media screen and  (min-width: 768px) {
    #somos-aliados-slide {
      background-image: url("../../assets/img/nosotros/sissa-digital/banner1.png");
    }
  }
  
  #somos-aliados-text {
    padding-left: 2rem;
  }
  
  #somos-aliados-text .gray-bold-title1 {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  #somos-aliados-text .red-thin-title1 {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  #somos-aliados-text .red-bold-title1 {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  #somos-aliados-text .red-rrss-container {
   
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }
  
  #somos-aliados-text .gray-bold-title1 {
    font-size: 8.5vw;
  }
  
  #somos-aliados-text .red-thin-title1 {
    font-size: 6vw;
  }
  
  #somos-aliados-text .red-bold-title1 {
    font-size: 17.5vw;
  }
  
  @media screen and  (min-width: 768px) {
    #somos-aliados-text .gray-bold-title1 {
      font-size: 3.4vw;
    }
    #somos-aliados-text .red-thin-title1 {
      font-size: 2.5vw;
    }
    #somos-aliados-text .red-bold-title1 {
      font-size: 7vw;
    }
  }
  
  @media screen and  (min-width: 768px) {
    #somos-aliados-text {
      padding-left: 5rem;
      padding-top: 5rem;
    }
  }

  @media screen and (min-width: 1024px){
    #somos-aliados-text .red-rrss-container {
      margin-left: 1rem;
     
    }
  }
  @media screen and (min-width: 1366px){
    #somos-aliados-text .red-rrss-container {
      margin-left: 3rem;
     
    }
  }
  @media screen and (min-width: 1850px){
    #somos-aliados-text .red-rrss-container {
      margin-left: 5.5rem;
     
    }
  }
  
  #desarrollamos-soluciones-slide {
    background-image: url("../../assets/img/nosotros/sissa-digital/banner2.png");
  }
  
  @media screen and  (min-width: 768px) {
    #desarrollamos-soluciones-slide {
      background-image: url("../../assets/img/nosotros/sissa-digital/banner2.png");
    }
  }
  
  #desarrollamos-soluciones-text {
    align-items: center;
  }
  
  #desarrollamos-soluciones-text .red-background-title {
    font-size: 0.7rem;
    text-align: center;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  #desarrollamos-soluciones-text .title1 {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  #desarrollamos-soluciones-text .title2 {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  #desarrollamos-soluciones-text .yk-carousel-caption {
    align-items: center;
  }
  
  @media screen and  (min-width: 768px) {
    #desarrollamos-soluciones-text {
      padding-right: 5rem;
    }
    #desarrollamos-soluciones-text .red-background-title {
      font-size: 1.7vw;
      text-align: center;
      max-width: 60vw;
      padding: 0px 50px;
    }
    #desarrollamos-soluciones-text .title1 {
      font-size: 3.3vw;
    }
    #desarrollamos-soluciones-text .title2 {
      font-size: 3vw;
      font-weight: 500;
    }
  }
  
  #mejoramos-slide {
    background-image: url("../../assets/img/nosotros/sissa-infraestructura/banner3.png");
  }
  
  @media screen and  (min-width: 768px) {
    #mejoramos-slide {
      background-image: url("../../assets/img/nosotros/sissa-infraestructura/banner1.png");
    }
  }
  
  #mejoramos-text .gray-thin-title1 {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  #mejoramos-text .gray-bold-title1 {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  #mejoramos-text .title3 {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  #mejoramos-text .red-background-title {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  #mejoramos-text .red-thin-title1 {
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
  }
  
  #mejoramos-text .red-rrss-container {
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }
  
  #mejoramos-text .gray-thin-title1 {
    font-size: 3rem;
    text-align: center;
  }
  
  #mejoramos-text .gray-bold-title1 {
    font-size: 2rem;
    text-align: center;
  }
  
  #mejoramos-text .title3 {
    font-size: 1.9rem;
    text-align: center;
  }
  
  #mejoramos-text .red-background-title {
    font-size: 1.8rem;
    text-align: center;
  }
  
  #mejoramos-text .red-thin-title1 {
    font-size: 1.8rem;
    text-align: center;
  }
  
  @media screen and  (min-width: 768px) {
    #mejoramos-text .gray-thin-title1 {
      font-size: 3vw;
      width: 40%;
    }
    #mejoramos-text .gray-bold-title1 {
      font-size: 3vw;
      width: 40%;
    }
    #mejoramos-text .title3 {
      font-size: 2.8vw;
      width: 40%;
    }
    #mejoramos-text .red-background-title {
      font-size: 3vw;
      width: 40%;
    }
    #mejoramos-text .red-thin-title1 {
      font-size: 2.8vw;
      width: 40%;
    }
  }
  
  @media screen and  (min-width: 768px) {
    #mejoramos-text {
      align-items: flex-end;
      padding-right: 4rem;
    }
  }
  
  #disenamos-soluciones-slide {
    background-image: url("../../assets/img/nosotros/sissa-infraestructura/banner2.png");
  }
  
  @media screen and  (min-width: 768px) {
    #disenamos-soluciones-slide {
      background-image: url("../../assets/img/nosotros/sissa-infraestructura/banner2.png");
    }
  }
  
  #disenamos-soluciones-text {
    align-items: center;
  }
  
  #disenamos-soluciones-text .title1 {
    color:#ffffff;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  #disenamos-soluciones-text .title2 {
    color:#ffffff;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  #disenamos-soluciones-text .title3 {
    color:#ffffff;
    font-size: 0.8rem;
    text-align: center;
    padding: 0px 1rem;
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
  }
  
  #disenamos-soluciones-text .yk-carousel-caption {
    align-items: center;
  }
  
  @media screen and  (min-width: 768px) {
    #disenamos-soluciones-text {
      padding-right: 5rem;
    }
    #disenamos-soluciones-text .title1 {
      font-size: 3.3vw;
      font-weight: 500;
    }
    #disenamos-soluciones-text .title2 {
      font-size: 3vw;
      font-weight: 300;
    }
    #disenamos-soluciones-text .title3 {
      font-size: 2.8vw;
    }
  }
  
  #implementamos-slide {
    background-image: url("../../assets/img/nosotros/sissa-infraestructura/banner3.png");
  }
  
  @media screen and  (min-width: 768px) {
    #implementamos-slide {
      background-image: url("../../assets/img/nosotros/sissa-infraestructura/banner3.png");
    }
  }
  
  #implementamos-text {
    align-items: center;
  }
  
  #implementamos-text .gray-thin-title1 {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  #implementamos-text .title2 {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  #implementamos-text .gray-bold-title1 {
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
  }
  
  #implementamos-text .red-bold-title1 {
    -webkit-animation-delay: 3.5s;
            animation-delay: 3.5s;
  }
  
  #implementamos-text .red-rrss-container {
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
  }
  
  #implementamos-text .gray-thin-title1 {
    font-size: 1.1rem;
    text-align: center;
  }
  
  #implementamos-text .title2 {
    font-size: 1.2rem;
    text-align: center;
  }
  
  #implementamos-text .gray-bold-title1 {
    font-size: 0.9rem;
    text-align: center;
  }
  
  #implementamos-text .red-bold-title1 {
    font-size: 1.2rem;
    text-align: center;
  }
  
  @media screen and  (min-width: 768px) {
    #implementamos-text .gray-thin-title1 {
      font-size: 2.8vw;
      text-align: center;
    }
    #implementamos-text .title2 {
      font-size: 3vw;
      text-align: center;
    }
    #implementamos-text .gray-bold-title1 {
      font-size: 1.7vw;
      text-align: center;
    }
    #implementamos-text .red-bold-title1 {
      font-size: 3vw;
      text-align: center;
    }
  }
  
  @media screen and  (min-width: 768px) {
    #implementamos-text {
      padding-left: 2rem;
    }
  }
  



  /*========================== NUEVO VERTIENTES =================== */

  .hex-panel-container{
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
  }
  .hex-container{
    display: flex;
    margin-top: -1rem !important;
  }




  @media screen and  (min-width:1500px){
    .hex-container{
      margin-top: -3rem !important;
    }
  }


  .vertientes-vertical-bar{
    border-radius: 25px;
    border-style: solid;
    border-color: #5b5b5f;
    box-shadow: 2px 1px 6px 0px rgba(0, 0, 0, 0.5);

    position: ABSOLUTE;
    MARGIN: AUTO;
    MARGIN-LEFT: 25%;
    WIDTH: 40%;
    HEIGHT: 100%;

  }


  .vertientes-left-column-icon{
      margin-left: inherit;
  }

  .vertientes-right-column-icon{
    margin-left: inherit;
  }


  @media screen and (min-width:1024px){
    .vertientes-left-column-icon{
      margin-left: -25px;
    }

    .vertientes-right-column-icon{
      margin-left: -25px;
    }
  }

  @media screen and (min-width: 1280px){
    .vertientes-vertical-bar{
      WIDTH: 43%;
    }
  }

  @media screen and (min-width: 1366px){
    .vertientes-left-column-icon{
      margin-left: -14px;
    }

    .vertientes-right-column-icon{
      margin-left: -61px;
    }

    .vertientes-vertical-bar{
      WIDTH: 30%;
    }
  }

  @media screen and (min-width: 1536px){
    .vertientes-vertical-bar{
      WIDTH: 46%;
    }
  }

  @media screen and (min-width: 1600px){
    .vertientes-vertical-bar{
      WIDTH: 47%;
    }

    .vertientes-left-column-icon{
      margin-left: -35px;
    }

    .vertientes-right-column-icon{
      margin-left: -15px;
    }
   /*  .honeycomb-section{ */
      /* Assuming that height is 900px aprox */
      /* min-height: 100vh;  */
    /* } */
  }


  @media screen and (min-width: 1900px){
    .vertientes-vertical-bar{
      WIDTH: 46%;
    }

    .vertientes-left-column-icon{
      margin-left: -35px;
    }

    .vertientes-right-column-icon{
      margin-left: -15px;
    }

    /* .honeycomb-section{ */
      /* Assuming that height is 900px aprox */
      /* min-height: 100vh;  */
    /* } */
  }

  .vertientes-separator-bottom{
    margin-top: 3rem;
  }


  @media screen and (min-width:1180px)
  {
    .vertientes-separator-bottom{
      margin-top: 5rem;
    }
  }



  .call-to-action-vertientes{
    display: flex;
    justify-content: center;
    
  }

@media screen and (min-width:768px){
  .call-to-action-vertientes{
   
    justify-content: flex-end;
  }
}

  /* Override bootsrap styles */
  .btn-link{
    color: #a62531 !important;
  }

  .btn-link:hover{
    color: #a62531 !important;
  }

