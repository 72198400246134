
  #integramosSoluciones-slide-bgImage {
    position: relative;
    
    float: left;
    width: 100%;
    height: 100vh;
    z-index: 0;

    background-image: url("../../assets/img/banner1.png");
    
    animation-duration: 20s;
    animation-name: scaleImageBackground;
  }

  @keyframes scaleImageBackground{
    0%{
      transform: scale(1);
    }
    50%{
      transform: scale(1.5);
    }
    100%{
      transform: scale(1);
    }
  }
  
  #integramosSoluciones-slide .yk-carousel-caption {
    position: absolute;
    margin-left: 25vw;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ffffff;
  }
  
  #integramosSoluciones-slide .yk-carousel-caption .title-1 {
    font-family: "Cabin", sans-serif;
    font-size: 6vw;
    font-weight: 200;
    color: #ffffff;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  #integramosSoluciones-slide .yk-carousel-caption .title-2 {
    font-family: "Cabin", sans-serif;
    font-size: 6vw;
    font-weight: 200;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  #integramosSoluciones-slide .yk-carousel-caption h5 {
    font-family: "Cabin", sans-serif;
    font-size: 1.5rem;
    font-weight: 200;
  }
  
  @media (min-width: 1024px) {
    #integramosSoluciones-slide .yk-carousel-caption .title-1, #integramosSoluciones-slide .yk-carousel-caption .title-2 {
      font-size: 4vw;
    }
  }
  
  #hacemosDeTusRetos {
    background-image: url("../../assets/img/banner2x.png");
  }
  
  #hacemosDeTusRetos .yk-carousel-caption #hacemosDeTusRetos-image {
    height: 25vh;
    background-size: cover;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  #hacemosDeTusRetos .yk-carousel-caption .title-1 {
    font-family: "Cabin", sans-serif;
    font-size: 3rem;
    font-weight: 200;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  #hacemosDeTusRetos .yk-carousel-caption .title-3 {
    font-family: "Cabin", sans-serif;
    font-size: 2.5rem;
    font-weight: 200;
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
  }
  
  #hacemosDeTusRetos .yk-carousel-caption .title-2 {
    font-family: "Cabin", sans-serif;
    font-size: 2rem;
    font-weight: 200;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  #hacemosDeTusRetos .yk-carousel-caption .title-4 {
    font-family: "Cabin", sans-serif;
    font-size: 1.5rem;
    font-weight: 200;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }
  
  #hacemosDeTusRetos #hacemosDeTusRetos-rrss {
    -webkit-animation-delay: 3.5s;
            animation-delay: 3.5s;
  }
  
  @media (min-width: 575px) {
    #hacemosDeTusRetos .yk-carousel-caption {
      padding-left: 4em;
    }
    #hacemosDeTusRetos .yk-carousel-caption .title-1 {
      font-size: 4rem;
    }
    #hacemosDeTusRetos .yk-carousel-caption .title-3 {
      font-size: 3.5rem;
    }
    #hacemosDeTusRetos .yk-carousel-caption .title-2 {
      font-size: 3rem;
    }
    #hacemosDeTusRetos .yk-carousel-caption .title-4 {
      font-size: 2.5rem;
    }
    #hacemosDeTusRetos .yk-carousel-caption #hacemosDeTusRetos-image {
      height: 400px;
      background-image: url("../../assets/img/banner2x-c.png");
    }
  }
  
  @media (min-width: 768px) {
    #hacemosDeTusRetos .yk-carousel-caption {
      flex-direction: row-reverse !important;
      justify-content: space-around !important;
    }
    #hacemosDeTusRetos .yk-carousel-caption #hacemosDeTusRetos-image {
      height: 610px;
      width: 600px;
    }
  }
  
  @media (min-width: 1024px) {
    #hacemosDeTusRetos .yk-carousel-caption .title-1 {
      font-size: 4rem;
    }
    #hacemosDeTusRetos .yk-carousel-caption .title-3 {
      font-size: 3.5rem;
    }
  }
  
  @media (min-width: 2048px) {
    #hacemosDeTusRetos .yk-carousel-caption .title-1 {
      font-size: 6rem;
    }
  }
  
  #nosEnfocamosEnResolver {
    background-image: url("../../assets/img/banner3.png");
  }
  
  #nosEnfocamosEnResolver .yk-carousel-caption img {
    height: 202px;
    height: 200px;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  #nosEnfocamosEnResolver .yk-carousel-caption .title-1 {
    font-family: "Cabin", sans-serif;
    font-size: 2.5rem;
    font-weight: 200;
    color: #ffffff;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  #nosEnfocamosEnResolver .yk-carousel-caption .title-3 {
    font-family: "Cabin", sans-serif;
    font-size: 2rem;
    font-weight: 200;
    color: #ffffff;
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
  }
  
  #nosEnfocamosEnResolver .yk-carousel-caption .title-2 {
    font-family: "Cabin", sans-serif;
    font-size: 1.5rem;
    font-weight: 200;
    color: #ffffff;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  #nosEnfocamosEnResolver .yk-carousel-caption .title-4 {
    font-family: "Cabin", sans-serif;
    font-size: 1rem;
    font-weight: 200;
    color: #ffffff;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }
  
  @media (min-width: 575px) {
    #nosEnfocamosEnResolver .yk-carousel-caption {
      padding-left: 4em;
    }
    #nosEnfocamosEnResolver .yk-carousel-caption .title-1 {
      font-size: 4rem;
    }
    #nosEnfocamosEnResolver .yk-carousel-caption .title-3 {
      font-size: 3.5rem;
    }
    #nosEnfocamosEnResolver .yk-carousel-caption .title-2 {
      font-size: 3rem;
    }
    #nosEnfocamosEnResolver .yk-carousel-caption .title-4 {
      font-size: 1.5rem;
    }
    #nosEnfocamosEnResolver .yk-carousel-caption img {
      height: 400px;
    }
  }
  
  @media (min-width: 768px) {
    #nosEnfocamosEnResolver .yk-carousel-caption {
      flex-direction: row-reverse !important;
      justify-content: space-around !important;
    }
    #nosEnfocamosEnResolver .yk-carousel-caption img {
      height: 610px;
      width: 600px;
    }
  }
  
  @media (min-width: 1024px) {
    #nosEnfocamosEnResolver .yk-carousel-caption .title-1 {
      font-size: 4rem;
    }
    #nosEnfocamosEnResolver .yk-carousel-caption .title-3 {
      font-size: 3.5rem;
    }
    #nosEnfocamosEnResolver .yk-carousel-caption .title-4 {
      width: 60%;
    }
  }
  
  @media (min-width: 2048px) {
    #nosEnfocamosEnResolver .yk-carousel-caption .title-1 {
      font-size: 6rem;
    }
  }
  
  #ocupamosElPuesto {
    background-image: url("../../assets/img/banner4.png");
  }
  
  #ocupamosElPuesto .yk-carousel-caption .title-2 {
    font-family: "Cabin", sans-serif;
    font-size: 3rem;
    font-weight: 200;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  #ocupamosElPuesto .yk-carousel-caption .title-1 {
    font-family: "Cabin", sans-serif;
    font-size: 5.5vw;
    font-weight: 200;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  #ocupamosElPuesto .yk-carousel-caption .title-3 {
    font-family: "Cabin", sans-serif;
    font-size: 1rem;
    font-weight: 200;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }
  
  #ocupamosElPuesto .yk-carousel-caption a {
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
  }
  
  #ocupamosElPuesto #ocupamosElPuesto-rrss {
    -webkit-animation-delay: 4.5s;
            animation-delay: 4.5s;
  }
  
  @media (min-width: 575px) {
    #ocupamosElPuesto .yk-carousel-caption .title-2 {
      font-size: 5rem;
    }
    #ocupamosElPuesto .yk-carousel-caption .title-2 {
      font-size: 4.5vw;
    }
   
    #ocupamosElPuesto .yk-carousel-caption .title-3 {
      font-size: 1.7rem;
    }
  }
  
  @media (min-width: 768px) {
    #ocupamosElPuesto .yk-carousel-caption {
      flex-direction: row-reverse !important;
      justify-content: space-around !important;
    }
  }
  
  @media (min-width: 1024px) {
    #ocupamosElPuesto .yk-carousel-caption .title-2 {
      font-size: 6rem;
    }
    #ocupamosElPuesto .yk-carousel-caption .title-1 {
      font-size: 4vw;
    }
    #ocupamosElPuesto .yk-carousel-caption .title-3 {
      font-size: 2rem;
    }
  }

  /* Termina Top Banner ========================================================================================== */

  #short-description{
    max-height: 60vh;
    min-height: 10vh !important;
    padding: 5rem 0;
  }

  #short-description-text {
    margin-top: 3rem;
  }
  #short-description-text p{
    font-size: 1.5rem;
  }
  
  #vertientes {
    background-image: url("../../assets/img/home/bg-1.png");
    padding-bottom: 30px;
  }
  
  #vertientes #description-container {
    background-color: #a62531;
    background-size: cover;
    width: 100%;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  #vertientes #description-container .container {
    height: 350px;
    display: flex;
  }
  
  #vertientes #description-container .container .row {
    flex-direction: column;
  }
  
  #vertientes .img-container {
    width: 100%;
  }
  
  #vertientes .img-container .row {
    width: 100%;
  }
  
  #vertientes .img-container .row .col-sm-4 {
    /* padding: 40px; */
    flex-direction: column;
    align-items: center;
  }
  
  #vertientes .img-container .row .col-sm-4 .btn-sissa-secondary {
    width: 80%;
    font-weight: 500;
  }
  
  .vertientes-text {
    text-align: center;
  }
  
  .vertientes-text .title-1 {
    font-size: 2em;
    width: 100vw;
    padding: 7px;
    background-color: #ffffff;
  }
  
  @media (min-width: 320px) {
    #vertientes .img-container {
      margin-top: -110px;
    }
  }
  
  @media (min-width: 1200px) {
    .vertientes-text p {
      font-size: 1em;
      margin-left: 9rem;
      margin-right: 10rem;
    }
  }
  
  #home-blog {
    background-image: url("../../assets/img/home/bg-1.png");
    padding-bottom: 30px;
  }
  
  #home-blog #description-container {
    background-color: #ecedef;
    background-size: cover;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  #home-blog #description-container .container {
    height: 350px;
  }
  
  #home-blog #description-container .container .row {
    flex-direction: column;
  }
  
  #home-blog .img-container {
    margin-top: -242px;
    width: 100%;
  }
  
  #home-blog .img-container .row {
    width: 100%;
  }
  
  #home-blog .img-container .row .col-sm-4 {
    display: flex;
    flex:1;
    /* padding: 40px; */
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  #home-blog .img-container .row .col-sm-4 figure{
    min-height: 507px;
  }

  
  #home-blog .img-container .row .col-sm-4 figure figcaption {
    margin-top: 15px;
  }
  
  #home-blog .img-container .row .col-sm-4 .btn-sissa-secondary {
    width: 200px;
    font-weight: 500;
  }
  
  .home-blog-text {
    text-align: center;
  }
  
  .home-blog-text h3 {
    width: 100vw;
    padding: 7px;
    background-color: #a62531;
    color: #ffffff;
  }
  
  @media (min-width: 1200px) {
    .home-blog-text p {
      font-size: 1.2rem;
      margin-left: 9rem;
      margin-right: 10rem;
    }
  }
  
  .fondoVideo {
    background-image: url("../../assets/img/home/bg-1.png");
    padding-top: 2.5em;
  }

  .tituloQuienesVideo{
    font-family: "Cabin", sans-serif;
    margin-top: 2.3em;
    color: #a62531;
    font-size: 3em;
  }

  .descripcionQuienesVideo{
    font-family: "Cabin", sans-serif;
    margin-top: 1.3em;
    font-size: 1.3em;
  }
  .videoPresentacion{
    margin-top: 2em;
    width: 100%;
  }
  .subtituloVideo{
    font-family: "Cabin", sans-serif;
    color: #a62531;
    margin-top: 1em;
    text-align: center;
  }
  .videoPresentacion2{
    width: 100%;
    margin-bottom: 2em;
  }

  @media screen and (min-width: 300px) and (max-width: 700px) {   
    .videoPresentacion{
      margin-top: 1em;
    }
    .descripcionQuienesVideo{
      text-align: center;
    }
    .subtituloVideo{
      margin-top: 1em;
      margin-bottom: 0px;
    }
    .videoPresentacion2{
      margin-top: 2em;
      margin-bottom: 1.3em;
    }
    .tituloQuienesVideo{
      margin-top: .5em;
    }
  }

  .containerVideo{
    box-shadow: 0px 4px 18px 6px #7575758c;
  }

  .centradoQuienes{
    position: relative;
    top: 50%
  }

  .espacios{
    margin-top: 4em;
  }