/* [class*="col-"] {
    display: flex;
 } */

 @font-face {
  font-family: SegoeUI;
  src:
      local("Segoe UI Light"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: SegoeUI;
  src:
      local("Segoe UI Semilight"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: SegoeUI;
  src:
      local("Segoe UI"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: SegoeUI;
  src:
  local("Segoe UI Semibold"),
  url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
  url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"),
  url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("truetype");
  font-weight: 600;
  font-display: swap;
  }
  
  @font-face {
  font-family: SegoeUI;
  src:
  local("Segoe UI Bold"),
  url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
  url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
  url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
  font-weight: 700;
  font-display: swap;
  }

 .h-90vh {
    height: 90vh !important;
  }
  
  .color-sissa-gray {
    color: #5b5b5f;
  }
  
  .color-sissa-p-red {
    color: #a62531;
  }

  .btn-sissa-primary-red {
    margin-top: 30px;
    border-radius: 30px !important;
    color: #ffffff !important;
    background-color: #a62531 !important;
    
  }

  .btn-sissa-primary-red:hover {
   
    background-color: #5B5B5F !important;
    transform: scale(1.1);
    animation-duration: 2s;
    animation: cubic-bezier(0, 0.62, 0.55, 1.07);
    text-decoration: none;
  }
  
  .btn-sissa-primary {
    margin-top: 30px;
    border-radius: 30px !important;
    color: #ffffff !important;
    background-color: #5b5b5f !important;
  }

  .btn-sissa-primary:hover {
    color: #ffffff;    
    background-color: #a62531 !important;
    transform: scale(1.1);
    animation: ease;
  }
  
  .btn-sissa-primary a:hover {
    color: #ffffff;    
    background-color: #a62531 !important;
    transform: scale(1.1);
    animation: ease;
  }
  
  .btn-sissa-primary:hover {
    background-color: #a62531;
    color: #ffffff;
    text-decoration: none;
  }
  
  .btn-sissa-secondary {
    width: 150px;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    margin-top: 15px;
    border-radius: 30px;
    color: #ffffff;
    background-color: #a62531;
    border: none;
   
  }
  
  .btn-sissa-secondary:hover {
    background-color: #5b5b5f !important;
    color: #ffffff;
    text-decoration: none;
    transform: scale(1.1);
    animation: ease;
  }
  
  .btn-sissa-white {
    width: 150px;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    margin-top: 15px;
    border-radius: 30px;
    background-color: #ffffff;
    color: #a62531;
  }
  
  .btn-sissa-white:hover {
    background-color: #5b5b5f;
    color: #ffffff;
    text-decoration: none;
  }
  
  .sissa-round-input {
    border: solid;
    border-color: #a62531;
    border-radius: 40px;
    padding: 0.3rem;
    text-align: center;
  }
  
  .input-icon {
    position: absolute;
    padding: 10px;
    color: #a62531;
    min-width: 50px;
    text-align: left;
    cursor: pointer;
  }
  
  .rrss-btn svg {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 20px;
    width: 2.5rem;
    cursor: pointer;
    -webkit-animation: transform 2s;
            animation: transform 2s;
    z-index: 9999;
  }
  
  .rrss-btn .st0 {
    stroke-width: 0px;
  }
  
  .rrss-btn svg:hover {
    transform: scale(1.3);
  }
  
  .rrss-btn svg:hover .st0 {
    fill: #a62531;
  }
  
  .hexagon-button-image {
    cursor: pointer;
  }
  
  .hexagon-button-image:hover {
    -webkit-animation: scaleanim 1s ease-in-out;
            animation: scaleanim 1s ease-in-out;
  }
  
  @-webkit-keyframes scaleanim {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes scaleanim {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .parallax-background {
   /*  min-height: 100vh; */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .gray-thin-title1 {
    color: #5b5b5f;
    font-weight: 200;
    line-height: 1;
  }
  
  .gray-bold-title1 {
    color: #5b5b5f;
    font-weight: 700;
    font-size: 7.5vw;
    line-height: 1;
  }
  
  .red-thin-title1 {
    color: #a62531;
    font-weight: 200;
    font-size: 9vw;
    line-height: 1;
  }
  
  .red-bold-title1 {
    color: #a62531;
    font-weight: 700;
    font-size: 17.5vw;
    line-height: 1;
  }
  
  @media (min-width: 768px) {
    .gray-bold-title1 {
      font-size: 3vw;
    }
    .red-thin-title1 {
      font-size: 3.6vw;
    }
    .red-bold-title1 {
      font-size: 7vw;
    }
  }
  
  .red-background-title {
    background-color: #a62531;
    color: #ffffff;
    font-weight: 500;
    border-radius: 5px;
  }
  
  .sissa-font-gray {
    color: #666666;
  }
  
  .sissa-paragraph-1 {
    font-size: 1.75rem !important;
    font-weight: 350 !important;
    text-align: justify !important;
  }
  
  .capital-text {
    font-size: 1.3rem;
    font-weight: 650;
  }
  
  .section-text h3 {
    font-size: 1.5rem;
  }
  
  .section-text {
    font-size: 1.2rem !important;
  }
  
  @media (min-width: 768px) {
    .capital-text {
      font-size: 1.9rem;
    }
    .section-text h3 {
      font-size: 1.9rem;
    }
    .section-text {
      font-size: 1.5rem !important;
    }
  }
  
  @media (min-width: 1024px) {
    .capital-text {
      font-size: 2.1rem;
    }
    .section-text h3 {
      font-size: 2.1rem;
    }
    .section-text {
      font-size: 1.75rem !important;
    }
  }
  
  @media (min-width: 1200px) {
    .capital-text {
      font-size: 2.5rem;
    }
    .section-text h3 {
      font-size: 2.3rem;
    }
    .section-text {
      font-size: 1.7rem !important;
    }
  }
  
  .separator-red-stripe {
    height: 2.5rem;
    width: 100%;
    background-color: #a62531;
  }
  
  .right-border-thin-gray {
    border-right: none;
  }
  
  @media (min-width: 768px) {
    .right-border-thin-gray {
      border-right: solid 2px #ecedef;
    }
  }
  
  .simple-hex-image-row {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 30px;
  }
  
  .simple-hex-image-row img {
    width: 40%;
    padding: 0.5rem 0.5rem 0rem 0.5rem;
  }
  
  .simple-hex-image {
    padding: 0.5rem 0.5rem 0rem 0.5rem;
  }
  
  @media (min-width: 768px) {
    .simple-hex-image {
      padding: 2rem 2rem 0rem 2rem;
    }
    .simple-hex-image-row img {
      width: 14%;
    }
  }
  
  @media (min-width: 1200px) {
    .simple-hex-image {
      padding: 2.5rem 2.5rem 0rem 2.5rem;
    }
  }
  
  .tw-logo .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #5B5B5F;
  }
  
  .fb-graycircle .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #5B5B5F;
  }
  
  .ig-graycircle .st0 {
    fill: #5B5B5F;
  }
  
  .in-graycirle .st0 {
    fill: #5B5B5F;
  }
  
  #short-description {
    /* background-image: url("../../img/home/bg-1.png"); */
    background-image: url("../../assets/img/home/bg-1.png");
  }
  
  #short-description #description-container {
    background-image: url("../../assets/img/home/short-description.png");
    background-size: cover;
    width: 100%;
  }
  
  #short-description .container {
    height: 350px;
  }
  
  #short-description-text {
    color: #000;
  }
  
  .sissa-paragraph-end .h-line {
    height: 1px;
    background-color: #a62531;
    width: 140px;
  }
  
  .sissa-paragraph-end .sissa-paragraph-end-hexagon {
    color: #a62531;
    font-size: 2.1rem;
  }
  
  #eventos {
    background-image: url("../../assets/img/home/bg-1.png");
    padding-bottom: 30px;
  }
  
  #eventos #description-container {
    background-color: #a62531;
    background-size: cover;
    width: 100%;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  #eventos #description-container .container {
    height: 350px;
  }
  
  #eventos #description-container .container .row {
    flex-direction: column;
  }
  
  #eventos .img-container {
    margin-top: -245px;
    width: 100%;
    justify-content: center;
  }
  
  #eventos .img-container .row {
    width: 84%;
  }
  
  #eventos .img-container .row .event-large {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
  
  #eventos .img-container .row .event-large .event-caption {
    width: 100%;
    background-color: #ffffff;
    flex-direction: column;
  }
  
  #eventos .img-container .row .event-large .event-caption .event-caption-text {
    color: #666666;
    flex-direction: column;
  }
  
  #eventos .img-container .row .event-large .event-caption .event-caption-button {
    justify-content: flex-end;
  }
  
  #eventos .img-container .row .event-large .btn-sissa-secondary {
    width: 200px;
    font-weight: 500;
  }
  
  #eventos .img-container .row .event-small-container {
    flex-direction: column;
    align-items: center;
  }
  
  #eventos .img-container .row .event-small-container .event-small {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
  
  #eventos .img-container .row .event-small-container .event-small .event-caption {
    width: 100%;
    background-color: #ffffff;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0.5rem 1rem 1.3rem;
  }
  
  #eventos .img-container .row .event-small-container .event-small .event-caption .event-caption-text {
    color: #666666;
    flex-direction: column;
  }
  
  #eventos .img-container .row .event-small-container .event-small .event-caption .event-caption-text h4 {
    font-size: 1rem;
    margin-bottom: 0px;
  }
  
  #eventos .img-container .row .event-small-container .event-small .event-caption .event-caption-text p {
    font-size: 0.6rem !important;
    margin-bottom: 0px;
  }
  
  #eventos .img-container .row .event-small-container .event-small .event-caption .event-caption-button {
    justify-content: flex-end;
    width: 90px;
    font-weight: 400;
    font-size: 0.6rem;
  }
  
  #eventos .img-container .row .event-small-container .event-small .event-caption .event-caption-button .btn-sissa-secondary {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .eventos-text {
    text-align: center;
  }
  
  .eventos-text h3 {
    width: 100vw;
    padding: 7px;
    background-color: #ffffff;
  }
  
  @media (min-width: 1200px) {
    .eventos-text p {
      font-size: 1.2rem;
      margin-left: 9rem;
      margin-right: 10rem;
    }
  }
  
  
  
  
  
  @media (min-width: 1200px) {
    .home-blog-text p {
      font-size: 1.2rem;
      margin-left: 9rem;
      margin-right: 10rem;
    }
  }
  
  .sissa-paragraph-end .h-line {
    height: 1px;
    background-color: #a62531;
    width: 140px;
  }
  
  .sissa-paragraph-end .sissa-paragraph-end-hexagon {
    color: #a62531;
    font-size: 2.1rem;
  }
  
  /* -------- FOOTER  ---------*/
  footer .separator-red-stripe {
    height: 1.75rem;
  }
  
  footer .container {
    justify-content: space-between;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
  
  footer .siguenos {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  footer .siguenos p {
    color: #5b5b5f;
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
    margin-left: 1rem;
  }
  
  footer .socios-footer-container {
    width: 90vw;
    justify-content: center;
  }
  
  footer .socios-footer-container img {
    margin-top: 20px;
    width: 80vw;
  }
  
  footer .copyright {
    margin-top: 1.75rem;
    color: #5b5b5f;
    display: flex;
  }
  
  footer .copyright a {
    color: #5b5b5f;
  }
  
  .footer-siguenos-socios {
    justify-content: space-between;
  }
  
  @media (min-width: 768px) {
    footer .siguenos {
      flex-direction: column;
      align-items: flex-start;
    }
    footer .siguenos p {
      font-size: 1.8rem;
    }
  }
  
  @media (min-width: 768px) {
    footer .socios-footer-container {
      width: 50vw;
    }
  }
  
   .red-rrss-container .fb-logo {
    width: 3vw !important;
  }
  
  .red-rrss-container .tw-logo {
    width: 6.5vw !important;
  }
  
  .red-rrss-container .yt-logo {
    width: 7vw !important;
  }
  
  .red-rrss-container .in-logo {
    width: 5vw !important;
  }
  
  .red-rrss-container .ig-logo {
    width: 5.5vw !important;
  }
  
  .red-rrss-container .at_sissamx {
    color: #a62531;
    font-weight: 500;
    font-size: 4vw !important;
  }
  
  @media (min-width: 576px) {
    .red-rrss-container .fb-logo {
      width: 2vw !important;
    }
    .red-rrss-container .tw-logo {
      width: 4.5vw !important;
    }
    .red-rrss-container .yt-logo {
      width: 4.3vw !important;
    }
    .red-rrss-container .in-logo {
      width: 3vw !important;
    }
    .red-rrss-container .ig-logo {
      width: 3vw !important;
    }
    .red-rrss-container .at_sissamx {
      color: #a62531;
      font-weight: 500;
      font-size: 3vw;
    }
  }
  
  @media (min-width: 768px) {
    .red-rrss-container .fb-logo {
      width: 1.4vw  !important;
    }
    .red-rrss-container .tw-logo {
      width: 3vw  !important;
    }
    .red-rrss-container .yt-logo {
      width: 3.2vw  !important;
    }
    .red-rrss-container .in-logo {
      width: 2.3vw  !important;
    }
    .red-rrss-container .ig-logo {
      width: 2.5vw  !important;
    }
    .red-rrss-container .at_sissamx {
      color: #a62531;
      font-weight: 500;
      font-size: 2.5vw  !important;
    }
  }
  
  @media (min-width: 1200px) {
    .red-rrss-container .fb-logo {
      width: 1vw  !important;
    }
    .red-rrss-container .tw-logo {
      width: 2.2vw  !important;
    }
    .red-rrss-container .yt-logo {
      width: 2.6vw  !important;
    }
    .red-rrss-container .in-logo {
      width: 1.8vw  !important;
    }
    .red-rrss-container .ig-logo {
      width: 1.9vw  !important;
    }
    .red-rrss-container .at_sissamx {
      color: #a62531;
      font-weight: 500;
      font-size: 1.7vw  !important;
    }
  }
  
  @media (min-width: 1500px) {
    .red-rrss-container .fb-logo {
      width: 0.8vw  !important;
    }
    .red-rrss-container .tw-logo {
      width: 1.7vw  !important;
    }
    .red-rrss-container .yt-logo {
      width: 2vw  !important;
    }
    .red-rrss-container .in-logo {
      width: 1.4vw  !important;
    }
    .red-rrss-container .ig-logo {
      width: 1.5vw  !important;
    }
    .red-rrss-container .at_sissamx {
      color: #a62531;
      font-weight: 500;
      font-size: 1.5vw  !important;
    }
  }
  
  /* ------------ Termina FOOTER  --------------*/
  .carousel-item {
    height: 90vh;
    min-height: 300px;
    background: no-repeat center center scroll;
    background-size: cover;
  }
  
  .portfolio-item {
    margin-bottom: 30px;
  }
  
  .navbar-toggler-icon {
    background-image: none !important;
  }
  
  body {
    
    background-color: #ffffff;
  }
  
  body div {
    color: #5b5b5f;
  }
  
  section {
    min-height: 65vh;
    scroll-margin-top: 250px;
  }
  
  .separator {
    height: 2px;
    background-color: #ecedef;
  }
  
  .separator-gray {
    height: 2px;
    background-color: #d4d4d4;
  }
  
  .section-nav {
    background-color: #ffffff;
    box-shadow: 0px 4px 18px 6px #8888888c;
    margin-top: 1rem;
  }
  
  .section-nav .container {
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    flex-wrap: wrap;
    justify-content: space-between;

  }
  
  .section-nav .container .section-nav-link {
    color: #a62531;
    white-space: nowrap;
    padding: 0px 1.5rem;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .nav-item {
    font-family: "Roboto", sans-serif;
  }
  
  .nav-item a {
    color: #a62531;
  }
  
  
  
  .red-text-bold {
    color: #a62531;
    font-weight: bold;
  }
  
  .portfolio {
    background-color: #ecedef;
    padding-top: 120px;
  }
  
  .portfolio .strip-background {
    background-color: #a62531;
    height: 180px;
  }
  
  .portfolio .img-container {
    margin-top: -290px;
    width: 100%;
  }
  
  .portfolio .img-container .row {
    width: 100%;
  }
  
  .portfolio .img-container .row .col-lg-4 {
    padding: 40px;
    flex-direction: column;
    align-items: center;
  }
  
  .portfolio .img-container .row .col-lg-4 .btn-sissa-secondary {
    width: 140px;
    font-weight: 500;
    margin-top: 0px;
  }
  
  .portfolio .img-container .row .col-lg-4 .img-caption {
    color: #5b5b5f;
    padding: 0.5rem 1rem;
    font-size: 1.2rem !important;
    margin-bottom: 0px;
  }
  
  .portfolio .portfolio-link {
    justify-content: flex-end;
    margin-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  .portfolio .portfolio-link a {
    padding: 0.5rem;
    border-bottom: solid;
    border-bottom-color: #a62531;
    border-bottom-width: 2px;
    color: #a62531;
    font-size: 2rem;
    font-weight: 500;
  }
  
  .red-rrss-container {
    align-items: center;
    flex-wrap: wrap;
  }
  
  .red-rrss-container .footer-rrss-logo {
    margin: 1rem 1vw;
  }
  
  
  .contactLinks{
    color:#0F4159;
  }
  



