
.dashboardFondo{
    background-color:white;
}

.tituloContenido{
    font-family: 'Times New Roman', Times, serif;
    font-size: 3em;
}

.subtituloContenido{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.3em;
    color: #868787;
}

.tituloCentrado{
    text-align: center;
    background-color: #ffffff;
}

.categoriasBlog{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: .9em;
    text-transform: uppercase;
    color: #a82433;
    margin-bottom: 0px;
}
.tituloBlog{
    font-family:  -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1.3em;
    color: black;
}
.descripcionBlog{
    font-family:  -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    color: #666666;
    text-align: none;
}
.cardBlog{
    border-radius: 0.75em;
    width: 350px;
    height: 580px;
    box-shadow: 0 2px 20px 0 rgba(17, 66, 158, 0.1);
    transition: all 0.4s ease-out;
    border: transparent;
}
.cardEvento{
    border-radius: 0.75em;
    box-shadow: 0 2px 20px 0 rgba(17, 66, 158, 0.1);
    transition: all 0.4s ease-out;
    border: transparent;
}
.cardEvento2{
    border-radius: 0.75em;
    box-shadow: 0 2px 20px 0 rgba(17, 66, 158, 0.1);
    transition: all 0.4s ease-out;
    border: transparent;
}

.ant-card-cover img{
    border-top-left-radius: 0.7em;
    border-top-right-radius: 0.7em;
}
.fotoBlog{
    border-radius: 0.5em;
}

.fotoBlogLista{
    max-width: 250px;
}
.blogTitulo{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3.2em;
}
.totalBlog{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.letraCategorias{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 30px;
}
.categoriaNota{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    color: #a82433;
}
.tituloNota{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    font-size: 3.2em;
}
.fotoNotaEspecifica{
    width: 60%;
}
.wrapper {
    position: relative;
    padding-top: 56.25%
}
  
.player {
   /*  position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.textoEnNota{
    width: 100%;
    text-align: left !important;
    font-family:  -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}
.textoEnNota p{
    font-family:  -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
    text-align: left !important;
}
.imagenBlogRelacionado{
    width: 100%;
}
.tituloBlogRelacionado{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(71, 71, 71);
    text-transform: uppercase;
    font-size: 1em;
}
.descripcionBlogRelacionado{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    font-size: 0.7em;
    margin-bottom: 5px;
    text-align: left;
    
}
.noHayRelacionados{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.4em;
}
.ant-tabs-bar{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.carousel-caption2{
    top: 50%;
    transform: translateY(-50%);
}
.carousel-caption{
    top: 40%;
    transform: translateY(-50%);
}
.letrasgrandes{
    color: white;
}
.letrasgrandesQUIENES{
    color:#a82433;
    text-align: left;
}
.letrasgrandes2{
    color: rgb(87, 86, 86);
    margin-bottom: 0px;
}
.letrasgrandes3{
    color: rgb(87, 86, 86);
    margin-bottom: 0px;
}
.otrasletras{
    color: white;
}
.otrasletrasQUIENES{
    color: rgb(87, 86, 86);
    text-align: left;
}
.otrasletras2{
    color: rgb(87, 86, 86);
}
.rojo{
    color: #a82433;
}

.letraSlide2{
    color: gray;
    float: left;
}
.imagenLeft{
    width: 150%;
}
.iconosRojos{
    color: rgb(87, 86, 86) !important; 
}
.iconosRojosVerdaderos{
    color: #a82433 !important; 
}
.iconosRojos:hover{
    color: #a82433 !important; 
}
.botonRojoConoce{
    color: white;
    background-color: #a82433 ;
    text-align: center;
    border-radius: 1em;
}
.botonGris{
    background-color:rgb(87, 86, 86);
    border-color: rgb(87, 86, 86);
    color: white;
    font-weight: 400;
    border-radius: 1em;
}
.botonGris:hover{
    background-color:rgb(59, 59, 59);
    color: white;
    border-color: rgb(59, 59, 59);
}

.imagenPartners{
    background-image: url('../../assets/img//partners/banner1.png'); 
    background-size: cover; 
    width: 100%;
    overflow: hidden;
}
.imagenContacto{
    background-image: url('../../assets/img/contacto/contacto.png'); 
    background-size: cover; 
    width: 100%;
    overflow: hidden;
}
.fondoGrisImagen{
    background-image: url('../../assets/img/banner4.png'); 
    background-size: cover; 
    width: 100%;
    overflow: hidden;
}
.fondoGrisQuienes{
    background-image: url('../../assets/img/nosotros/ante-cada-reto-background.png'); 
    background-size: cover; 
    width: 100%;
    overflow: hidden;
}
.fondoMundo{
    background-image: url('../../assets/img/home/short-description.png'); 
    background-size: cover; 
    width: 100%;
}
.tituloRojo{
    color: #a82433;
    font-weight: bold;
}

.fondoRojo{
    background-color: #a82433;
}

.textRojiBlando{
    color: white;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 2em;
}
.textRojiBlancoBlog{
    color: white;
    font-weight: bold;
}
.textoReto{
    color: #a82433;;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}
.sissa-paragraph-end .sissa-paragraph-end-hexagon {
    color: #a62531;
    font-size: 2.1rem;
}
.sissa-paragraph-end .h-line {
    height: 1px;
    background-color: #a62531;
    width: 160px;
}
.containerLetras{
    text-align: justify;
}
.titloEvento{
    color: #a62531;
    font-weight: bold;
}
.titloEvento2{
    color: #a62531;
    font-weight: bold;
}
.imagenEvento{
    width: 100%;
}
.letrasGrisesSigan{
    color: #5b5b5f;
    font-weight: 500;
}
.letrasGrisesSigan2{
    color: #5b5b5f;
    font-weight: 500;
}

.nosotros-short-description {
    border-left-color: #a62531;
    border-left-width: 0.5rem;
    border-left-style: solid;
    padding: 0px 0px 0px 1.2rem;
    color: #666666;
    text-align: justify;
}
.digital-short-description{
    border-left-color: #5b5b5f;
    background-color: #a62531;
    border-left-width: 0.5rem;
    border-left-style: solid;
    padding: 0px 0px 0px 1.2rem;
    color: #666666;
}
.rojoTextoQuienes{
    color: #a62531;
}
.blancoTextoQuienes{
    color: white;
}
.fondoBlancoFlotante{
    box-shadow: 0 2px 20px 0 rgba(3, 11, 26, 0.3);
    transition: all 0.4s ease-out;
    border: transparent;
}
.rojocentroquienes{
    color: #a62531;
    text-align: center;
}
.griscentroquienes{
    text-align: center;
    color: #5b5b5f;
}

.centrarCosos{
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%, 0%);
}

.centrarCosos2{
    justify-content: center;
    align-items: center;
}

.nuestrosPartners1{
    color: white;
    text-shadow: 1px 1px 2px black; 
    margin-bottom: 0px;
}
.nuestrosPartners2{
    color: white;
    text-shadow: 1px 1px 2px black; 
    margin-bottom: 0px;

}
.fondoBlancoFlotanteRayaRoja{
    border-top: solid;
    border-top-color: currentcolor;
    border-top-width: medium;
    border-color: #a62531;
    border-top-width: 0.5rem;
    padding: 3rem 0px;
    background-color: #ffffff;
    box-shadow: 0px 4px 18px 6px #8888888c;
}
.atencion1{
    text-align: center;
    color: #a62531 ;
    font-weight: bolder;
}
.atencion2{
    text-align: center;
    color: #a62531 ;
}
.cardMapa{
    /* position: relative !important; */
    width: 100%;
    border-color: white;
    height: 500px;
}
.btnMenu{
    padding-left: 2.5em;
    padding-right: 2.5em;
}
.letrasRojas{
    color: #a62531 ;
    text-align: center;
}
.iconoLocation{
    width: 15%;
}
.iconoTelefono{
    width: 15%;
}
.colorLetrasTablas{
    color: black;
}
.btnNuevoAdmin{
    color: white;
    background-color:  #a62531 ;
    border-radius: 1em;
    margin-bottom: 1em;
}

.float {
    position: fixed;
    width: 61px;
    height: 61px;
    bottom: 1.7em;
    left: .7em;
    background-color: #a62531 ;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}
.my-float {
    margin-top: 0.4em;
}

.galeriaImagenes{
    width: 100%;
}


.espacioArriba{
    margin-top: 1em;
    margin-bottom: 1em;
}

.cardBlog .ant-card-body{
    max-height: 300px;
    overflow: hidden;
}

.descripcionBlog{
text-align: left;
}

.cardEvento2{
width: 95%;
}

@media (min-width: 300px) and (max-width: 700px){
    .responsiveEspacioArriba{
        margin-top: 1em;
        margin-bottom: .7em;
    }
    .cardEvento{
        width: 80%;
    }
}

  @media(min-width:800px){
    .cardEvento2{
      width: 75%;
    }
    .titloEvento2{
        max-height: 66px;
        overflow: hidden;
    }
    
    .infoevento3{
    max-height: 74px;
    overflow: hidden;
    }
  }

  @media(min-width:1200px){
    .titloEvento2{
      font-size: 1rem;
    }
    .infoevento3{
      font-size: 0.8rem;
    }
  }


  @media(max-width: 990px){
    .cardEvento{
      max-width: 90% !important;
    }
  }
  .textoEnNota img{
      width: 100% !important;
  }