@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.dashboardFondo{
    background-color:white;
}
.ovalo{

   background-position: right center;
  
   /* Background image doesn't tile */
   background-repeat: no-repeat;
   
   /* Background image is fixed in the viewport so that it doesn't move when 
      the content's height is greater than the image's height */
   background-attachment: fixed;
   
   /* This is what makes the background image rescale based
      on the container's size */
   background-size: contain;
   width: 100%;
  
}




@media (min-width: 900px) { 
  .tituloQuienes{
    font-size: 45px;
    font-weight: 500;
    border-left-color: #491214;
    border-left-width: 0.5em;
    border-left-style: solid;
    text-align: left;
    margin-left: 1.5em;
  }
  .tituloPagina{
    font-size: 45px;
    font-weight: 600;
    text-align: center;
    color: #a5282f;
  }
  .letrasQuienes{
    padding: 6em 0;
    font-size: 20px;
  }
  .logo-quienes {
    width: 80%;
    margin-left: 3em;
  }
  .logo-quienes2{
    width: 100%;
  }
  .letrasQuienes2{
    font-size: 22px;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .letrasLista{
    font-size: 22px;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .atras{
    position: absolute;
    z-index:1
  }
  .adelante{
    z-index:2;
    position: absolute;
  }
  .logo-quienes4{
    width: 80%;
    margin-top:2.75em;
  }
  .logo-quienes3{
    width: 80%;
  }
  .letrasRegistro{
    margin-top: 2em;
    font-size: 22px;
  }
  .letrasRegistro2{
    margin-top: 5em;
    font-size: 22px;
  }
  .imagenRegistro{
    width: 100%;
  }
  .celularRegistro{
    width: 95%;
    position: relative;
  }
  .escritorioRegistro{
    width: 100%;
    position: relative;
  }
  .cuadroAzul{
    margin-top: 2em;
  }
  .celularPantallas{
    width: 60%;
  }
}



@media screen and (min-width: 300px) and (max-width: 899px) {   
  .tituloQuienes{
    font-size: 25px;
    text-align: center;
    font-weight: 600;
    border-left-color: #60181c;
    border-left-width: 0.5em;
    border-left-style: solid;
  }
  .letrasQuienes{
    margin-top: 1em;
    font-size: 20px;
    text-align: center;
  }
  .logo-quienes {
    width: 100%;
  }
  .logo-quienes2{
    width: 100%;
  }
  .letrasQuienes2{
    font-size: 20px;
    margin-top: 1em;
    text-align: center;
  }
  .letrasLista{
    font-size: 20px;
    margin-top: 1em;
    text-align: left;
    margin-top: .8em;
  }
  .imagenRegistro{
    width: 100%;
  }
  .celularRegistro{
    width: 40%;
    position: relative;
  }
  .escritorioRegistro{
    width: 70%;
    position: relative;
  }
  .letrasRegistro2{
    margin-top: 1em;
    font-size: 17px;
    text-align: center;
  }
  .letrasRegistro{
    text-align: center;
  }
  .tituloPagina{
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: #a5282f;
  }

  
}


.fondoAzul{
  background-color: #a4252e;
}
.imagenFooter{
  width: 70%;
}
.imagenFooter2{
  width: 60%;
}
.imagenFooter3{
  width: 80%;
}

.iconoFooter{
  color: #a4252e;
}
.quienesCard{
  background-color: #a4252e;
  border-radius: 1em;
}
.quienesCard2{
  background-color: #60181c;
  border-radius: 1em;
}

.letrasQuienes{
  font-family: 'Raleway', sans-serif;
}
.letrasQuienes2{
  font-family: 'Raleway', sans-serif;
}
.letrasLista{
  font-family: 'Raleway', sans-serif;
}
.tituloQuienes{
  color: #a4252e;
  font-family: 'Raleway', sans-serif;
}

.letrasFooter{
  font-family: 'Raleway', sans-serif;
}
.letrasGrises{
  color: gray;
  font-family: 'Raleway', sans-serif;
}
.letrasRegistro{
  font-family: 'Raleway', sans-serif;
}
.letrasRegistro2{
  font-family: 'Raleway', sans-serif;
}
.letrasPantallas{
  font-family: 'Raleway', sans-serif;
}